import React from 'react';
import PropTypes from 'prop-types';
import './submitparent.css';

export default function SubmitParent(props) {
  const { children, screentype } = props;
  return (
    <div className={screentype === 'desktop' ? 'home-submit-parent' : 'home-submit-mobile-parent'}>{ children }</div>
  );
}

SubmitParent.propTypes = {
  /**
     children element, able to add in the html element
    */
  children: PropTypes.element,
  /**
     screentype: desktop, tablet, children
    */
  screentype: PropTypes.string.isRequired,
};

SubmitParent.defaultProps = {
  children: <div />,
};
