import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from './image/close.svg';
import './mobilemapcontainer.css';

export default function MobileMapContainer(props) {
  const { children, setMapOp } = props;
  const btnClose = () => {
    // alert('btn search clicked');
    setMapOp(false);
  };
  return (
    <div className="basic-mobile-container">
      <div
        className="basic-mobile-close-container"
        role="button"
        onClick={() => btnClose()}
        aria-hidden="true"
      >
        <img
          loading="lazy"
          src={CloseIcon}
          alt="alt"
          style={{ cursor: 'pointer', margin: '0px 4px 0px 0px ' }}
        />
        HIDE MAP
      </div>
      { children }
    </div>
  );
}
MobileMapContainer.propTypes = {
  /**
            to display time.
        */
  children: PropTypes.element,
  /**
            to display 'min' 'hour' etc.
        */
  setMapOp: PropTypes.func.isRequired,
};

MobileMapContainer.defaultProps = {
  children: <div />,
};
