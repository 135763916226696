import React from 'react';
import PropTypes from 'prop-types';
import './refreshbutton.css';

export default function RefreshButton(props) {
  const { btnRefresh, screentype } = props;
  return (
    <div className="refresh-container">
      <div className={screentype === 'desktop' ? 'container-refresh-with-text' : 'container-refresh-with-text-mobile '}>
        <div className="refresh-text">Your journey is out of date</div>
        <button type="button" className="btn-refresh" onClick={() => btnRefresh()}>REFRESH</button>
      </div>
    </div>
  );
}

RefreshButton.propTypes = {
  /**
        onClick refresh button.
      */
  btnRefresh: PropTypes.func.isRequired,
  /**
       screen type mobile, desktop or tablet
      */
  screentype: PropTypes.string.isRequired,
};
