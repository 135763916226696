import React from 'react';
import PropTypes from 'prop-types';
import './joptioncardparent.css';

export default function JOptionCardParentOne(props) {
  const { children, screentype } = props;
  return (
    <div className={screentype === 'desktop' ? 'joptioncardone-container' : 'joptioncardone-mobile-container'}>
      <div className="joptioncardone-divider">{' '}</div>
      {children}
    </div>
  );
}

JOptionCardParentOne.propTypes = {
  /**
    props for children element
      */
  children: PropTypes.element,
  /**
    screentype: desktop / tablet / mobile
      */
  screentype: PropTypes.string.isRequired,
};

JOptionCardParentOne.defaultProps = {
  children: <div />,
};
