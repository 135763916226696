import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
  display: block;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(0%, -50%);
`;

export default function Loaders(props) {
  const { color, loading } = props;
  return (
    <div>
      <ClipLoader color={color} loading={loading} css={override} size={35} />
    </div>
  );
}

Loaders.propTypes = {
  /**
              change color on hex value
    */
  color: PropTypes.string.isRequired,
  /**
              loading boolean. If true display false not display
    */
  loading: PropTypes.bool,
};

Loaders.defaultProps = {
  loading: false,
};
