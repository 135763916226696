import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import LightDriveIcon from './image/light-drive.svg';
import DarkDriveIcon from './image/dark-drive.svg';
import InformationIcon from './image/information.svg';
import './toggle.css';

// color theme for toggle button
const colorTheme = createTheme({
  palette: {
    primary: {
      // light: '#757ce8',
      main: '#F16022',
      // dark: '#002884',
      // contrastText: '#fff',
    },
  },
});

export default function Toggle(props) {
  const {
    setAlignment, alignment, displayImg, screentype,
  } = props;

  // onChange toggle button
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const displayInformation = (type) => {
    if (type === 'desktop') {
      return (
        <div className="toggle-information">Trek will suggest different journey option(s) based on your selection</div>
      );
    }
    return (
      <Tooltip title="Trek will suggest different journey option(s) based on your selection">
        <img loading="lazy" src={InformationIcon} alt="icon" style={{ margin: '0px 0px 0px 8px' }} />
      </Tooltip>
    );
  };

  return (
    <div className={screentype === 'desktop' ? 'toggle-container' : 'toggle-mobile-container'}>
      {/* <div className="withhex">
        <div style={screentype !== 'desktop' && screentype !== 'tablet'
        ? { fontSize: '14px', fontWeight: 700, margin: '0px 4px 0px 0px' } :
        { fontSize: '18px', fontWeight: 700, margin: '0px 4px 0px 0px' }}>&#9312;</div>
        <div className="dash-submit"> &#8212; </div>
        <div style={{ fontSize: '14px', fontWeight: 700 }}>Do you intend to drive?</div>
      </div> */}
      <div className="hex-numner-container">
        <div className="hex-nummer-number">&#9312;</div>
        <div className="hex-nummer-dash">&#8212;</div>
        <div className="hex-nummer-digit">Do you intend to drive?</div>
      </div>
      <div className="detail-toggle-container">
        {/* {displayImg && <img loading="lazy" src={LightDriveIcon}
        alt="img-display" className="toggle-img" />} */}
        {displayImg && <img loading="lazy" src={DarkDriveIcon} alt="img-display" className="toggle-img" />}
        <ThemeProvider theme={colorTheme}>
          <ToggleButtonGroup
            fullWidth
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            size="small"
            color="primary"
            sx={{ background: '#FFFFFF', borderRadius: '8px', height: '24px' }}
          >
            <ToggleButton value="left" aria-label="left aligned">
              <div className="toggle-text">Not Driving</div>
            </ToggleButton>
            <ToggleButton value="right" aria-label="centered">
              <div className="toggle-text">Driving</div>
            </ToggleButton>
          </ToggleButtonGroup>
        </ThemeProvider>
        {screentype === 'mobile' && displayInformation(screentype)}
      </div>
      <div>
        {screentype === 'desktop' && (
          <div className="toggle-information">Trek will suggest different journey option(s) based on your selection</div>
        )}
      </div>
    </div>
  );
}

Toggle.propTypes = {
  /**
   setState value - function when change state when toggle
  */
  setAlignment: PropTypes.func.isRequired,
  /**
   State value - default value are 'left'
  */
  alignment: PropTypes.string.isRequired,
  /**
   to display image, value boolean
  */
  displayImg: PropTypes.bool,
  /**
   screentype: screentype desktop, tablet, mobile
  */
  screentype: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  displayImg: true,
};
