import React from 'react';
import PropTypes from 'prop-types';
import DropOffIcon from './image/dark-dropoff.svg';
import GreenLive from './image/green-live.svg';
import YellowLive from './image/yellow-live.svg';
import RedLive from './image/red-live.svg';
import './dropoffleg.css';

export default function DropoffLeg(props) {
  const { screentype, data, functionDuration } = props;

  console.log('dropOffLeg', data);
  const statusCOntext = (x) => {
    switch (x.statusContext) {
      case 'smooth': // green
        return '#286551';
      case 'heavy': // yellow
        return '#D9A517';
      case 'standstill': // red
        return '#EA2B1F';
      default:
        return '#00000';
    }
  };
  const imgContext = (x) => {
    switch (x.statusContext) {
      case 'smooth': // green
        return GreenLive;
      case 'heavy': // yellow
        return YellowLive;
      case 'standstill': // red
        return RedLive;
      default:
        return GreenLive;
    }
  };
  const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    // return {r, g, b} // return an object
    return `rgba(${r}, ${g}, ${b}, 0.15)`;
  };
  return (
    <div className={screentype === 'desktop' ? 'journey-details-dropoff-leg-container' : 'journey-details-mobile-dropoff-leg-container'}>
      <div className="journey-details-dropoff-detail">
        <img loading="lazy" src={DropOffIcon} alt="walk" />
        <div className="journey-details-dropoff-stop-container">
          <p className="get-dropoff">Get dropped off at</p>
          {
            data.to.nextLegPreview.mode !== 'WALK' ? (
              <div
                className="trainid-container"
                style={data.to.nextLegPreview.mode === 'BUS' ? {
                  border: '0.5px solid #128110',
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  fontSize: '11px',
                } : {
                  backgroundColor: `#${data.to.nextLegPreview.routeColor}`,
                  color: `#${data.to.nextLegPreview.routeTextColor}`,
                  fontSize: '11px',
                }}
              >
                {data.to.nextLegPreview.routeShortName
                  ? data.to.nextLegPreview.routeShortName
                  : data.to.nextLegPreview.routeId}
              </div>
            ) : null
          }
          <p className="train-stop-name-container" style={data.to.nextLegPreview.mode === 'BUS' || data.to.nextLegPreview.mode === 'WALK' ? { color: '#000000' } : { color: `#${data.to.nextLegPreview.routeColor}` }}>{data.to.name}</p>
          <p className="get-dropoff">
            {data.to.nextLegPreview.mode === 'RAIL' ? 'Station' : 'Bus Station'}
          </p>
        </div>
      </div>
      <div className={screentype === 'desktop' ? 'journey-details-dropoff-time' : 'journey-details-mobile-dropoff-time'} style={{ background: hex2rgb(`#${data.statusColour}`) }}>
        <img loading="lazy" src={imgContext(data)} alt="live" className="dropoffleg-img" />
        <p className="j-dropoff-time" style={{ color: statusCOntext(data) }}>{functionDuration(data.duration)}</p>
        <p className="j-dropoff-measure" style={{ color: statusCOntext(data) }}>min</p>
      </div>
    </div>
  );
}
DropoffLeg.propTypes = {
  /**
      screentype desktop, tablet, mobile
    */
  screentype: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  functionDuration: PropTypes.func.isRequired,
};
