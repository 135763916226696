import React from 'react';
import PropTypes from 'prop-types';
import './searchbutton.css';

export default function Searchbutton(props) {
  const { text, onClick, disabledBtn } = props;

  return (
    // eslint-disable-next-line no-unneeded-ternary
    <button type="button" className={disabledBtn ? 'btn-disabled' : 'btn-search'} disabled={disabledBtn ? false : true} onClick={onClick}>{text}</button>
  );
}

Searchbutton.propTypes = {
  /**
      to display text inside button
  */
  text: PropTypes.string,
  /**
      to display text inside button
  */
  onClick: PropTypes.func.isRequired,
  /**
      disabled button
  */
  disabledBtn: PropTypes.bool,
};

Searchbutton.defaultProps = {
  text: 'GO',
  disabledBtn: false,
};
