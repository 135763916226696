/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import PropTypes, { shape } from 'prop-types';
import moment from 'moment';
// import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
import { Appbar } from './trek-web-component/ui/header-appbar/index';
import { Drawer } from './trek-web-component/ui/header-drawer/index';
import { Sidebar } from './trek-web-component/ui/sidebar-parent/index';
import { Toggle } from './trek-web-component/ui/driving-toggle-button/index';
import { AutocompleteParent } from './trek-web-component/ui/autocomplete-parent/index';
import { Autocompletes } from './trek-web-component/ui/geo-autocomplete/index';
import { Swapbutton } from './trek-web-component/ui/swapbutton/index';
import { SubmitParent } from './trek-web-component/ui/home-submit-parent/index';
import { Menu } from './trek-web-component/ui/depature-menu/index';
import { Searchbutton } from './trek-web-component/ui/search-button/index';
import { Preferencebutton } from './trek-web-component/ui/preference-button/index';
import { Map } from './trek-web-component/ui/map/index';
import { DateTimeContainer } from './trek-web-component/ui/date-time/index';
import { JourneyOptionParent } from './trek-web-component/ui/journey-option-parent/index';
import { JourneyAddress } from './trek-web-component/ui/journey-address-parent/index';
import { Backbutton } from './trek-web-component/ui/journeyaddress-backbutton/index';
import { JourneyListOrigin } from './trek-web-component/ui/journey-list-origin/index';
import { JourneyListEnd } from './trek-web-component/ui/journey-list-end/index';
import { JOptionCardParentOne } from './trek-web-component/ui/journey-op-card-pone/index';
import { JourneyOptionParentsTwo } from './trek-web-component/ui/journey-op-card-ptwo/index';
import { JourneyCardDepature } from './trek-web-component/ui/journey-option-card-depature/index';
import { Multimodel } from './trek-web-component/ui/multi-model/index';
import { Price } from './trek-web-component/ui/price-tag/index';
import { TimeDuration } from './trek-web-component/ui/time-duration/index';
import { ModesDuration } from './trek-web-component/ui/modes-duration/index';
import { Travelmode } from './trek-web-component/ui/slider-card-parent/index';
import { SingleMode } from './trek-web-component/ui/single-mode/index';
import { JourneyOverviewP } from './trek-web-component/ui/journey-detail-over-parent';
import { MultiModelIcon } from './trek-web-component/ui/multi-model-only-icon/index';
import { TimeDetailDuration } from './trek-web-component/ui/time-details-duration/index';
import { JdetailShowButton } from './trek-web-component/ui/jdetail-show-map-button/index';
import { MobileMapContainer } from './trek-web-component/ui/mobile-map-container/index';
import { WalkLeg } from './trek-web-component/ui/journey-detail-walk-leg/index';
import { Divider } from './trek-web-component/ui/journey-detail-divider/index';
import { DropoffLeg } from './trek-web-component/ui/journey-detail-dropoff-leg/index';
import { DestinationLeg } from './trek-web-component/ui/journey-detail-destination-leg/index';
import { DriveLeg } from './trek-web-component/ui/journey-detail-drive-leg/index';
import { TrainLeg } from './trek-web-component/ui/journey-detail-train-leg/index';
import { BusLeg } from './trek-web-component/ui/journey-detail-bus-leg/index';
import { Loaders } from './trek-web-component/ui/loaders/index';
import { RefreshButton } from './trek-web-component/ui/refresh-button/index';
import AMLogo from './img/AMLogo.png';

momentDurationFormatSetup(moment);
function Tablet(props) {
  const {
    setAlignment,
    alignment,
    isLoaded,
    setSwap,
    swap,
    setValFrom,
    valFrom,
    setValTo,
    valTo,
    setResultAPI,
    resultAPI,
    setResultAPITo,
    resultAPITo,
    setTxtvalue,
    txtValue,
    setTxtvalueTo,
    txtValueTo,
    setSelect,
    select,
    setDatetime,
    datetime,
    setDisplayOp,
    displayOp,
    setDisplayDet,
    displayDet,
    setDisplayMap,
    displayMap,
    handleDrawerOpen,
    options,
    journeyDetails,
    open,
    setItenary,
    APIOptionCalled,
    itenary,
    setIsLoaded,
    setDisplayRefresh,
    displayRefresh,
    disabledBtn,
    setDetParking,
    detParking,
    resultDetails,
    setResultDetails,
    setOptionHover,
    optionHover,
    setPolyLineHover,
    polyLineHover,
    btnResetTimer,
    increment,
    singleMode,
    multiMode,
    setSingleMode,
    setMultiMode,
    setUuid,
    uuids,
    setCenterLat,
    centerLat,
    APISearchSelect,
    onLoadCoord,
    loadCoordInd,
    refForButton,
  } = props;
  const reference = useRef(null);
  const browserUtcOffset = Math.abs(new Date().getTimezoneOffset() / 60);
  const swapButton = () => {
    setSwap(!swap);
    const from = valFrom;
    const to = valTo;
    setValFrom(to);
    setValTo(from);
    setTxtvalueTo(txtValue);
    setTxtvalue(txtValueTo);
  };

  const refreshTimer = () => {
    increment.current = setInterval(() => {
      setDisplayRefresh(true);
    }, 60000);
  };

  const btnSearch = async () => {
    const isDriving = alignment === 'right'; // this to indicate if driving option
    const isStop = valFrom.group === 'STOPS' && valTo.group === 'STOPS'; // this to indicate if stop option
    const OriginCoords = `${valFrom.lat}, ${valFrom.lng}`; // parameter for Origin Coords
    const DestCoords = `${valTo.lat},${valTo.lng}`; // parameter for DestCoords
    const TimeISO = moment(datetime).utc(browserUtcOffset).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    APIOptionCalled(isDriving, isStop, OriginCoords,
      DestCoords, setItenary, setIsLoaded, TimeISO, select); // to call option API
    refreshTimer();
    setDisplayOp(true);
  };

  const btnBack = async () => {
    if (displayDet) {
      setDisplayDet(false);
      setResultDetails({});
      setDisplayRefresh(false);
      btnResetTimer();
      refreshTimer();
      setDisplayMap(false);
      setDetParking([]);
      setPolyLineHover([]);
    } else {
      setDisplayOp(false);
      setDisplayDet(false);
      setItenary([]);
      setDisplayRefresh(false);
      setResultDetails({});
      setPolyLineHover([]);
      setSingleMode([]);
      setMultiMode([]);
      btnResetTimer();
    }
  };

  // to convert seconds to minute
  const functionDuration = (value) => {
    const duration = moment.duration(value, 'seconds');
    const formatted = duration.format('m');
    return formatted;
  };

  // to convert seperate minute and hour
  const functionMinHourDuration = (value, isHour) => {
    const hours = Math.floor(value / 3600); // convert to hour
    const minutes = Math.floor((value - (hours * 3600)) / 60); // convert to minute
    if (isHour) {
      return hours;
    }
    return minutes;
  };

  const checkAlignment = (value) => { // check if the option card are driving or transit
    if (value.includes('DRIVE') || value.includes('KNR')) {
      return 'DRIVE';
    }
    return 'TRANSIT';
  };

  // price converter.
  const priceConverter = (value) => {
    const realPrice = value / 100;
    const roundedString = realPrice.toFixed(2);
    return roundedString;
  };

  const btnRefresh = async () => {
    const isDriving = alignment === 'right'; // this to indicate if driving option
    const isStop = valFrom.group === 'STOPS' && valTo.group === 'STOPS'; // this to indicate if stop option
    const OriginCoords = `${valFrom.lat}, ${valFrom.lng}`; // parameter for Origin Coords
    const DestCoords = `${valTo.lat},${valTo.lng}`; // parameter for DestCoords
    setItenary([]);
    setSingleMode([]);
    setMultiMode([]);
    const TimeISO = moment(datetime).utc(browserUtcOffset).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    APIOptionCalled(isDriving, isStop, OriginCoords,
      DestCoords, setItenary, setIsLoaded, TimeISO, select); // to call option API
    setDisplayRefresh(false);
    btnResetTimer();
    refreshTimer(); // refresh back time out
  };

  // useEffect(() => {
  //   if (displayDet) {
  //     const { zpbt } = window;
  //     zpbt.serviceObject.create(uuids);
  //     zpbt.serviceObject.addService(uuids, 'gtfs', 'bus', '1000016', '1000027', true, true, {
  //       time: '2024-01-12T19:10:00+01:00', // Local time of departure/arrival (inISO 8601 standard)
  //       origin: '1000016', // Origin code
  //       destination: '1000027', // Destination code
  //       custom_data: {}, // Custom data related to the service (e.g. service ID,operator etc
  //     });
  //     zpbt.serviceObject.commit(uuids);
  //     zpbt.serviceObject.renderButton(uuids, reference.current, 'Keep Me Updated!', null, null, ['#f16022', '#f16022'], '#FFFFFF', 'large', 'test_button', true, ['facebook'], null, null);
  //   }
  // }, [displayDet]);

  useEffect(() => {
    if (Object.keys(resultDetails).length > 0) {
      const transitModes = ['RAIL', 'BUS', 'TRAIN'];
      const checkIfHaveTransit = resultDetails.legs.length > 0 ? resultDetails.legs.some((x) => transitModes.includes(x.mode)) : false;
      if (checkIfHaveTransit) {
        const takeFirstLeg = resultDetails.legs.filter((x) => transitModes.includes(x.mode));
        const { from, to, mode } = takeFirstLeg[0];
        const regex = /:(.*)/;
        const matchFrom = from.stopId.match(regex);
        const matchTo = to.stopId.match(regex);
        const todaysDate = moment().utc(browserUtcOffset).format('YYYY-MM-DDTHH:mm:ss.SSS');
        if (matchFrom.length > 0 && matchTo.length > 0) {
          const { zpbt } = window;
          zpbt.serviceObject.create(uuids);
          zpbt.serviceObject.addService(uuids, 'gtfs', mode, matchFrom[1].trim(), matchTo[1].trim(), true, true, {
            time: todaysDate, // Local time of departure/arrival (inISO 8601 standard)
            origin: matchFrom[1].trim(), // Origin code
            destination: matchTo[1].trim(), // Destination code
            custom_data: {}, // Custom data related to the service (e.g. service ID,operator etc
          });
          zpbt.serviceObject.commit(uuids);
          zpbt.serviceObject.renderButton(uuids, reference.current, 'Keep Me Updated!', null, null, ['#f16022', '#f16022'], '#FFFFFF', 'large', 'test_button', true, ['facebook'], null, null);
        }
        // console.log('takeFirstLeg', {
        //   matchFrom: matchFrom[1].trim(),
        //   matchTo: matchTo[1].trim(),
        //   todaysDate,
        //   mode,
        // });
      }
    }
  }, [resultDetails]);

  const functionMouseEnter = (x, i) => {
    // console.log('hello mouse enter');
    setOptionHover({ hover: true, index: i });
    setPolyLineHover(x.legs);
  };

  const functionMouseLeave = (x, i) => {
    // console.log('hello mouse leave');
    setOptionHover({ hover: false, index: i });
    setPolyLineHover([]);
  };

  const clickedWaze = (e, value) => {
    e.preventDefault();
    const from = { lat: value.from.lat, lng: value.from.lon };
    const to = { lat: value.to.lat, lng: value.to.lon };
    const url = `https://www.waze.com/live-map/directions?navigate=yes&from=ll.${from.lat}%2C${from.lng}&to=ll.${to.lat}%2C${to.lng}`;
    window.open(url, '_blank');
  };

  const clickedGoogleMap = (e, value) => {
    e.preventDefault();
    const from = { lat: value.from.lat, lng: value.from.lon };
    const to = { lat: value.to.lat, lng: value.to.lon };
    const url = `https://www.google.com/maps/dir/?api=1&origin=${from.lat},${from.lng}&destination=${to.lat},${to.lng}&travelmode=driving`;
    window.open(url, '_blank');
  };

  // console.log('resultDetails', resultDetails);

  const cardMode = (x) => {
    switch (x.mode) {
      case 'DRIVE':
        return (
          <DriveLeg screentype="mobile" data={x} parking={detParking} functionDuration={functionDuration} clickedWaze={clickedWaze} clickedGoogleMap={clickedGoogleMap} />
        );
      case 'RAIL':
        return (
          <TrainLeg screentype="mobile" data={x} functionDuration={functionDuration} />
        );
      case 'TRAIN':
        return (
          <TrainLeg screentype="mobile" data={x} functionDuration={functionDuration} />
        );
      case 'BUS':
        return (
          <BusLeg screentype="mobile" data={x} functionDuration={functionDuration} />
        );
      case 'KNR':
        return (
          <DropoffLeg screentype="mobile" data={x} functionDuration={functionDuration} />
        );
      case 'WALK':
        return (
          <WalkLeg screentype="mobile" data={x} functionDuration={functionDuration} />
        );
      default:
        return (
          <div />
        );
    }
  };

  return (
    <div>
      <Appbar screentype="mobile" handleDrawerOpen={handleDrawerOpen} open={open} />
      <Drawer open={open} />
      {
          displayOp ? (
            <JourneyOptionParent screentype="tablet">
              <div style={{
                margin: '0px 0px 16px 0px', position: 'sticky', top: 0, zIndex: 2,
              }}
              >
                <JourneyAddress screentype="tablet">
                  <Backbutton onClick={() => btnBack()} />
                  <div>
                    <JourneyListOrigin
                      landmark={valFrom.name}
                      fulladdress={valFrom.details}
                    />
                    <JourneyListEnd
                      landmark={valTo.name}
                      fulladdress={valTo.details}
                    />
                  </div>
                </JourneyAddress>
              </div>
              {
                displayDet ? (
                  <div>
                    <div
                      style={{
                        marginLeft: 8,
                        marginRight: 8,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      ref={reference}
                    />
                    <div className="overview-mobile-journey">
                      <JourneyOverviewP screentype="tablet" valPadding="8px">
                        <div>
                          <MultiModelIcon data={resultDetails.legs} />
                          {
                            resultDetails.fare && resultDetails.fare.fare && resultDetails.fare.fare.regular && (
                              <div style={{ margin: '4px 0px 0px 0px' }}>
                                <Price
                                  currency="RM"
                                  price={priceConverter(resultDetails.fare.fare.regular.cents)}
                                />
                              </div>
                            )
                          }
                        </div>
                      </JourneyOverviewP>
                      <JourneyOverviewP screentype="tablet" valPadding="18px 7px" second>
                        <TimeDetailDuration
                          hour={functionMinHourDuration(resultDetails.duration, true)}
                          min={functionMinHourDuration(resultDetails.duration, false)}
                        />
                      </JourneyOverviewP>
                    </div>
                    {
                      displayMap ? (
                        <div style={{ padding: '8px' }}>
                          <MobileMapContainer setMapOp={setDisplayMap}>
                            <Map
                              screentype="mobile"
                              data={itenary}
                              origin={{
                                lat: parseFloat(valFrom.lat),
                                lng: parseFloat(valFrom.lng),
                              }}
                              destination={{
                                lat: parseFloat(valTo.lat),
                                lng: parseFloat(valTo.lng),
                              }}
                              displayOp={displayOp}
                              resultDetails={resultDetails}
                              optionHover={optionHover}
                              polyLineHover={polyLineHover}
                              setCenterLat={setCenterLat}
                              refForButton={refForButton}
                            />
                          </MobileMapContainer>
                        </div>
                      ) : (
                        <div style={{ padding: '8px' }}>
                          <JdetailShowButton setMapOp={setDisplayMap} text="Show Map" />
                        </div>
                      )
                  }
                    <div className="journey-detail-mobile-container">
                      {
                           Object.keys(resultDetails).length > 0
                           && resultDetails.legs.length > 0
                           && resultDetails.legs.map((x) => (
                             <div>
                               {cardMode(x)}
                               {x.mode !== 'PARK' && <Divider />}
                             </div>
                           ))
                      }
                      <DestinationLeg landmark={valTo.name} fulladdress={valTo.details} screentype="tablet" />
                    </div>
                  </div>
                )
                  : (
                    <div>
                      <Loaders color="#F16022" loading={isLoaded} />
                      {displayRefresh && <RefreshButton btnRefresh={() => btnRefresh()} screentype="tablet" />}
                      {
                        multiMode.length > 0 && multiMode.map((x, i) => (
                          <div
                            className="card-option-mobile"
                            role="button"
                            onKeyPress={() => journeyDetails(x)}
                            onClick={() => journeyDetails(x)}
                            onMouseEnter={() => functionMouseEnter(x, i)}
                            onMouseLeave={() => functionMouseLeave(x, i)}
                            tabIndex={0}
                          >
                            <JOptionCardParentOne screentype="tablet">
                              <div style={{ margin: '0px 0px 4px 0px' }}>
                                <JourneyCardDepature
                                  type={checkAlignment(x.modeSummary)}
                                  stops={x.legs}
                                />
                              </div>
                              <div style={{ margin: '0px 0px 4px 0px' }}>
                                <Multimodel data={x.legs} />
                              </div>
                              {
                              x.fare && x.fare.fare && x.fare.fare.regular && (
                                <div style={{ margin: '4px 0px 0px 0px' }}>
                                  <Price
                                    currency="RM"
                                    price={priceConverter(x.fare.fare.regular.cents)}
                                  />
                                </div>
                              )
                            }
                            </JOptionCardParentOne>
                            <JourneyOptionParentsTwo screentype="tablet">
                              <TimeDuration
                                hours={functionMinHourDuration(x.duration, true)}
                                min={functionMinHourDuration(x.duration, false)}
                                screentype="tablet"
                              />
                              <div style={{ margin: '4px 0px 0px 0px' }}>
                                <ModesDuration
                                  walk={functionDuration(x.walkTime)}
                                  overall={functionDuration(x.waitingTime)}
                                  screentype="tablet"
                                />
                              </div>
                            </JourneyOptionParentsTwo>
                          </div>
                        ))
                      }
                      {!isLoaded && singleMode.length > 0 && (
                      <div style={{ margin: '8px 0px 8px 8px' }}>
                        <Travelmode text="More Options" />
                      </div>
                      )}
                      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
                        {
                        singleMode.length > 0 && singleMode.map((x, i) => (
                          <div>
                            <div>
                              <div
                                role="button"
                                style={{ margin: '0px 8px 0px 8px', cursor: 'pointer' }}
                                onKeyPress={() => journeyDetails(x)}
                                onClick={() => journeyDetails(x)}
                                onMouseEnter={() => functionMouseEnter(x, i)}
                                onMouseLeave={() => functionMouseLeave(x, i)}
                                tabIndex={i}
                              >
                                <SingleMode
                                  modeType={x.modeSummary[0]}
                                  hour={functionMinHourDuration(x.duration, true)}
                                  min={functionMinHourDuration(x.duration, false)}
                                  screentype="tablet"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      </div>

                    </div>
                  )
              }

            </JourneyOptionParent>
          ) : (
            <Sidebar screentype="tablet">
              <Toggle alignment={alignment} setAlignment={setAlignment} displayImg screentype="tablet" />
              <AutocompleteParent text="Where to?" screentype="tablet">
                <div className="app-autocomplete">
                  <Autocompletes list={resultAPI} txtValue={txtValue} isLoaded={isLoaded} text="From" setTxtvalue={setTxtvalue} setValAutocomplete={setValFrom} setResult={setResultAPI} valAutocomplete={valFrom} setUuid={setUuid} uuids={uuids} APISearchSelect={APISearchSelect} onLoadCoord={onLoadCoord} loadCoordInd={loadCoordInd} />
                  <Autocompletes
                    list={resultAPITo}
                    txtValue={txtValueTo}
                    isLoaded={isLoaded}
                    text="To"
                    setTxtvalue={setTxtvalueTo}
                    setValAutocomplete={setValTo}
                    setResult={setResultAPITo}
                    valAutocomplete={valTo}
                    setUuid={setUuid}
                    uuids={uuids}
                    APISearchSelect={APISearchSelect}
                    onLoadCoord={onLoadCoord}
                    loadCoordInd={loadCoordInd}
                  />
                </div>
                <div>
                  <Swapbutton onClick={swapButton} />
                </div>
              </AutocompleteParent>
              <SubmitParent screentype="tablet">
                <Menu options={options} setSelect={setSelect} select={select} />
              </SubmitParent>
              {
              select.value === 'depart' || select.value === 'arrive' ? <div style={{ margin: '0px 16px 8px 16px' }}><DateTimeContainer setDateTime={setDatetime} dateTime={datetime} /></div> : null
          }
              {/* <div ref={refForButton} id="zipabout_container" /> */}
              {/* <Preferencebutton text="ADVANCE SETTINGS" /> */}
              <div style={{ textAlign: 'left', width: 182 }}>
                <div style={{ padding: '0px 16px 0px 16px' }}>
                  <Searchbutton text="GO" onClick={() => btnSearch()} disabledBtn={disabledBtn} />
                </div>
                <div style={{
                  padding: '0px 16px 0px 16px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                }}
                >
                  <div style={{ fontSize: '8px', margin: '0px 2px 0px 0px' }}>Powered By</div>
                  <div>
                    <img
                      src={AMLogo}
                      alt="amlogo"
                      style={{
                        width: '56.0625px', height: '8.9px', position: 'relative', bottom: '2.5px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </Sidebar>
          )
        }
    </div>
  );
}

export default Tablet;

Tablet.propTypes = {
  setAlignment: PropTypes.func.isRequired,
  alignment: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  setSwap: PropTypes.func.isRequired,
  swap: PropTypes.string.isRequired,
  setValFrom: PropTypes.func.isRequired,
  valFrom: PropTypes.string.isRequired,
  setValTo: PropTypes.func.isRequired,
  valTo: PropTypes.string.isRequired,
  setResultAPI: PropTypes.func.isRequired,
  resultAPI: PropTypes.string.isRequired,
  setResultAPITo: PropTypes.func.isRequired,
  resultAPITo: PropTypes.string.isRequired,
  setTxtvalue: PropTypes.func.isRequired,
  txtValue: PropTypes.string.isRequired,
  setTxtvalueTo: PropTypes.func.isRequired,
  txtValueTo: PropTypes.string.isRequired,
  setSelect: PropTypes.func.isRequired,
  select: PropTypes.shape().isRequired,
  setDatetime: PropTypes.func.isRequired,
  datetime: PropTypes.string.isRequired,
  setDisplayOp: PropTypes.func.isRequired,
  displayOp: PropTypes.bool.isRequired,
  setDisplayDet: PropTypes.func.isRequired,
  displayDet: PropTypes.bool.isRequired,
  setDisplayMap: PropTypes.func.isRequired,
  displayMap: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  options: PropTypes.shape().isRequired,
  journeyDetails: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setItenary: PropTypes.func.isRequired,
  itenary: PropTypes.func.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  APIOptionCalled: PropTypes.func.isRequired,
  setDisplayRefresh: PropTypes.func.isRequired,
  displayRefresh: PropTypes.bool.isRequired,
  disabledBtn: PropTypes.bool.isRequired,
  setDetParking: PropTypes.func.isRequired,
  detParking: PropTypes.arrayOf(shape()).isRequired,
  resultDetails: PropTypes.shape().isRequired,
  setResultDetails: PropTypes.func.isRequired,
  setOptionHover: PropTypes.func.isRequired,
  optionHover: PropTypes.bool.isRequired,
  setPolyLineHover: PropTypes.func.isRequired,
  polyLineHover: PropTypes.shape().isRequired,
  btnResetTimer: PropTypes.func.isRequired,
  increment: PropTypes.func.isRequired,
  singleMode: PropTypes.arrayOf(shape()).isRequired,
  multiMode: PropTypes.arrayOf(shape()).isRequired,
  setSingleMode: PropTypes.func.isRequired,
  setMultiMode: PropTypes.func.isRequired,
  setUuid: PropTypes.func.isRequired,
  uuids: PropTypes.string.isRequired,
  setCenterLat: PropTypes.func.isRequired,
  centerLat: PropTypes.shape().isRequired,
  APISearchSelect: PropTypes.func.isRequired,
  onLoadCoord: PropTypes.shape().isRequired,
  loadCoordInd: PropTypes.bool.isRequired,
  refForButton: PropTypes.func.isRequired,
};
