import React from 'react';
import PropTypes from 'prop-types';
import Car from './image/dark-car.svg';
import Bus from './image/dark-bus.svg';
import Train from './image/train.svg';
import Park from './image/parking.svg';
import GreyChevron from './image/grey-chevron.svg';
import DropOff from './image/dark-dropoff.svg';
import WalkingIcon from './image/walking.svg';
import './multimodelicon.css';

export default function MultiModelIcon(props) {
  const { data } = props;
  const settingMode = (x) => {
    switch (x.mode) {
      case 'DRIVE':
        return (
          <div className="joverview-detail-multi-container">
            <img loading="lazy" src={Car} alt="car" />
          </div>
        );
      case 'TRAIN':
        return (
          <div className="joverview-detail-multi-container">
            <img loading="lazy" src={Train} alt="train" />
          </div>
        );
      case 'RAIL':
        return (
          <div className="joverview-detail-multi-container">
            <img loading="lazy" src={Train} alt="train" />
          </div>
        );
      case 'PARK':
        return (
          <div className="joverview-detail-multi-container">
            <img loading="lazy" src={Park} alt="park" />
          </div>
        );
      case 'BUS':
        return (
          <div className="joverview-detail-multi-container">
            <img loading="lazy" src={Bus} alt="bus" />
          </div>
        );
      case 'KNR':
        return (
          <div className="joverview-detail-multi-container">
            <img loading="lazy" src={DropOff} alt="bus" />
          </div>
        );
      case 'WALK':
        return (
          <div className="joverview-detail-multi-container">
            <img loading="lazy" src={WalkingIcon} alt="bus" />
          </div>
        );
      default:
        return (
          <div />
        );
    }
  };
  return (
    <div className="joverview-detail-arrow-container">
      {
              // data.map((x) => x.mode.map((value, i, arr) => (
              //   arr.length - 1 === i ? (
              //     <div className="joverview-detail-arrow">
              //       {/* last item */}
              //       {settingMode(value)}
              //     </div>
              //   ) : (
              //     <div className="joverview-detail-arrow">
              //       {/* not last item */}
              //       {settingMode(value)}
              //       <img loading="lazy" src={GreyChevron}
              //        alt="chev" style={{ margin: '0px 2px 0px 2px' }} />
              //     </div>
              //   )
              // )))
              data.length > 0 && data.map((x, i, arr) => (arr.length - 1 === i ? (
                <div className="joption-arrow">
                  {settingMode(x)}
                </div>
              ) : (
                <div className="joption-arrow">
                  {settingMode(x)}
                  <img
                    loading="lazy"
                    src={GreyChevron}
                    alt="chev"
                    style={{ margin: '0px 2px 0px 2px' }}
                  />
                </div>
              )))
          }
    </div>
  );
}

MultiModelIcon.propTypes = {
  /**
          list of data modes
      */
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};
