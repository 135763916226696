import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import './journeyoptionparent.css';

export default function JourneyOptionParent(props) {
  const { children, screentype } = props;
  const [size, setSize] = useState({
    y: window.innerHeight,
    x: window.innerWidth,
  });
    /// --------------this is for responsive height to fixed the empty space in big screen
  useLayoutEffect(() => {
    function updateSize() {
      setSize({
        y: window.innerHeight,
        x: window.innerWidth,
      }); // take the windows height and - 56px. The 56px are height from top bar
    }
    window.addEventListener('resize', updateSize);
    updateSize(); // update the size based on screen change
    return () => window.removeEventListener('resize', updateSize); // return the updated height value, this value will add in div elements for sideBar and Panel
  }, []);
  const options = (type) => {
    switch (type) {
      case 'desktop':
        return (
          <div className="journeyoptionparent-desktop-container" style={{ height: `${size.y - 44}px` }}>
            <div>
              {children}
            </div>
          </div>
        );
      case 'tablet':
        return (
          <div className="journeyoptionparent-mobile-container" style={{ height: `${size.y}px` }}>
            <div>
              {children}
            </div>
          </div>
        );
      case 'mobile':
        return (
          <div className="journeyoptionparent-mobile-container" style={{ height: `${size.y}px`, width: `${size.x}px` }}>
            <div>
              {children}
            </div>
          </div>
        );
      default:
        return (
          <div className="journeyoptionparent-desktop-container" style={{ height: size.y }}>
            <div>
              {children}
            </div>
          </div>
        );
    }
  };
  return (
    <div>
      {options(screentype)}
    </div>
  );
}

JourneyOptionParent.propTypes = {
  /**
     the value use for screentype are "desktop", "mobile" and "tablet"
    */
  screentype: PropTypes.string.isRequired,
  /**
     children element, able to add in the html element
    */
  children: PropTypes.element,

};

JourneyOptionParent.defaultProps = {
  children: <div />,
};
