import React from 'react';
import PropTypes from 'prop-types';
import './autocompleteparent.css';

export default function AutocompleteParent(props) {
  const { children, text, screentype } = props;
  return (
    <div>
      <div className={screentype === 'desktop' ? 'autocomplete-parent-text' : 'autocomplete-parent-mobile-text'}>
        <div className="hex-number-autocomplete-container">
          <div className="hex-number-autocomplete">&#9313;</div>
          <div className="dash-submit-autocomplete"> &#8212; </div>
          {text}
        </div>
      </div>
      <div className={screentype === 'desktop' ? 'autocomplete-parent-container' : 'autocomplete-parent-mobile-container'}>{ children }</div>
    </div>
  );
}

AutocompleteParent.propTypes = {
  /**
   children element, able to add in the html element
  */
  children: PropTypes.element,
  /**
   adding in text
  */
  text: PropTypes.string,
  /**
   screentype: desktop, tablet, mobile
  */
  screentype: PropTypes.string.isRequired,
};

AutocompleteParent.defaultProps = {
  children: <div />,
  text: '',
};
