import React from 'react';
import PropTypes from 'prop-types';
import DarkCar from './image/dark-car.svg';
import DarkCycle from './image/dark-cycle.svg';
import DarkWalk from './image/dark-walk.svg';
import './singlemode.css';

export default function SingleMode(props) {
  const {
    modeType, hour, min, screentype,
  } = props;

  const settingMode = (mode) => {
    switch (mode) {
      case 'DRIVE':
        return DarkCar;
      case 'CYCLE':
        return DarkCycle;
      case 'WALK':
        return DarkWalk;
      default:
        return DarkWalk;
    }
  };
  return (
    <div className={screentype === 'desktop' ? 'joption-singlemode-container' : 'joption-singlemode-mobile-container'}>
      <img loading="lazy" src={settingMode(modeType)} alt="alt" style={screentype === 'desktop' ? { margin: '0px 10px 0px 0px' } : { margin: '0px 2px 0px 0px' }} />
      {
        hour > 0 && (
        <div className="joption-singlemode-moreoption">
          <div className={screentype === 'desktop' ? 'joption-primary' : 'joption-mobile-primary'}>{hour}</div>
          <div className={screentype === 'desktop' ? 'joption-secondary' : 'joption-mobile-secondary'}>hr</div>
        </div>
        )
      }

      <div className="joption-singlemode-moreoption" style={{ margin: '0px 0px 0px 2px' }}>
        <div className={screentype === 'desktop' ? 'joption-primary' : 'joption-mobile-primary'}>{min}</div>
        <div className={screentype === 'desktop' ? 'joption-secondary' : 'joption-mobile-secondary'}>min</div>
      </div>
    </div>
  );
}

SingleMode.propTypes = {
  /**
        value from setState
      */
  modeType: PropTypes.string,
  /**
      value hour
    */
  hour: PropTypes.number,
  /**
      value min
    */
  min: PropTypes.number,
  /**
        screentype: desktop, tablet, mobile
      */
  screentype: PropTypes.string.isRequired,
};

SingleMode.defaultProps = {
  modeType: '',
  hour: 0,
  min: 0,
};
