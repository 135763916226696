import React from 'react';

import PropTypes from 'prop-types';

import './modeduration.css';
import GreyTime from './image/grey-time.svg';
import GreyWalk from './image/grey-walk.svg';

export default function ModesDuration(props) {
  const { walk, overall, screentype } = props;
  return (
    <div className={screentype === 'desktop' ? 'joption-mode-duration-container' : 'joption-mode-duration-mobile-container'}>
      <div className="joption-mode-container" style={{ margin: '0px 0px 4px 0px' }}>
        <img loading="lazy" src={GreyWalk} alt="walk-icon" />
        <div className="joption-mode-text">{`${walk} min`}</div>
      </div>
      <div className="joption-mode-container">
        <img loading="lazy" src={GreyTime} alt="walk-icon" />
        <div className="joption-mode-text">{`${overall} min`}</div>
      </div>
    </div>
  );
}

ModesDuration.propTypes = {
  /**
        to display time.
    */
  walk: PropTypes.string,
  /**
        to display 'min' 'hour' etc.
    */
  overall: PropTypes.string,
  /**
        screentype: desktop, mobile, tablet
    */
  screentype: PropTypes.string.isRequired,
};

ModesDuration.defaultProps = {
  walk: '',
  overall: '',
};
