/* eslint-disable react/no-children-prop */
import React, { useState, useRef } from 'react';
import PropTypes, { shape } from 'prop-types';
import ReactMarkdown from 'react-markdown';
import CarIcon from './image/dark-car.svg';
import GoogleMapIcon from './image/googleMap.svg';
import Waze from './image/waze.svg';
import ParkIcon from './image/parking-modes.svg';
import GreenLive from './image/green-live.svg';
import YellowLive from './image/yellow-live.svg';
import RedLive from './image/red-live.svg';
import AccordionIcon from './image/accordion.svg';
import './driveleg.css';

export default function DriveLeg(props) {
  const {
    screentype, data, parking, functionDuration, clickedWaze, clickedGoogleMap,
  } = props;
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('drive_accordion__icon_group_status');

  const content = useRef(null);

  const accordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`,
    );
    setRotateState(
      setActive === 'active' ? 'drive_accordion__icon_group_status' : 'drive_accordion__icon_group_status drive-rotate',
    );
  };

  const statusCOntext = (x) => {
    // console.log(x);
    switch (x.statusContext) {
      case 'smooth': // green
        return '#286551';
      case 'heavy': // yellow
        return '#D9A517';
      case 'standstill': // red
        return '#EA2B1F';
      default:
        return '#00000';
    }
  };

  const imgContext = (x) => {
    switch (x.statusContext) {
      case 'smooth': // green
        return GreenLive;
      case 'heavy': // yellow
        return YellowLive;
      case 'standstill': // red
        return RedLive;
      default:
        return GreenLive;
    }
  };

  const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    // return {r, g, b} // return an object
    return `rgba(${r}, ${g}, ${b}, 0.15)`;
  };

  const displayParkingType = (value) => {
    switch (value) {
      // park and ride
      case '1':
        return (
          <div className="car-legs-parking-container">
            <img
              loading="lazy"
              src={ParkIcon}
              alt="park"
              style={{ margin: '0px 4px 0px 0px' }}
            />
            <div className="car-legs-onstreetparking-container">Park and Ride</div>
          </div>
        );
      case '2':
        return null;
      case '3':
        // on-Street parking
        return (
          <div className="car-legs-parking-container">
            <img
              loading="lazy"
              src={ParkIcon}
              alt="park"
              style={{ margin: '0px 4px 0px 0px' }}
            />
            <div className="car-legs-onstreetparking-container">On-Street Parking</div>
          </div>
        );

      default:
        return (
          <img
            loading="lazy"
            src={ParkIcon}
            alt="park"
            style={{ margin: '0px 4px 0px 0px' }}
          />
        );
    }
  };

  return (
    <div className={screentype === 'desktop' ? 'journey-detail-car-leg-real-container' : 'journey-details-mobile-car-leg-real-container'}>
      <div className={screentype === 'desktop' ? 'journey-details-car-leg-container' : 'journey-details-mobile-car-leg-container'}>
        <img loading="lazy" src={CarIcon} alt="walk" className="img-car-icon" />
        <div className="journey-details-car-detail">
          <div className="journey-details-car-stop-container">
            <p className="get-car">
              Drive to
              <b style={{ margin: '0px 0px 0px 4px ' }}>{data.to.name}</b>
            </p>
            {
              parking.length > 0 && (
                <div className="car-legs-parking-container">
                  {displayParkingType(parking[0].parking.parkingType)}
                  {/* Mall Parking */}
                  {
                    parking[0].parking.parkingType && parking[0].parking.parkingType === '2' && (
                      <>
                        {
                          parking[0].parking.availableBays ? (
                            <div
                              className="journey-details-parking-duration-container"
                              style={{ backgroundColor: hex2rgb(`#${parking[0].parking.statusColour}`) }}
                            >
                              {!parking[0].parking.realTime && (
                                <img
                                  loading="lazy"
                                  src={GreenLive}
                                  alt="live"
                                  className="car-img"
                                />
                              )}
                              <p
                                className="j-car-time"
                                style={{ color: '#286551' }}
                              >
                                {parking[0].parking.availableBays}
                              </p>
                              <p className="j-car-measure-bay" style={{ color: '#286551' }}>bays</p>
                            </div>
                          ) : (
                            <img
                              loading="lazy"
                              src={ParkIcon}
                              alt="park"
                              style={{ margin: '0px 4px 0px 0px' }}
                            />
                          )
                        }
                      </>

                    )
                  }
                </div>
              )
            }
            {/* {
            parking.length > 0 && parking[0].parking.parkingType
            && parking[0].parking.parkingType === '1' && (
              <div className="car-legs-parking-container">
                <img
                  loading="lazy"
                  src={ParkIcon}
                  alt="park"
                  style={{ margin: '0px 4px 0px 0px' }}
                />
                <div className="car-legs-onstreetparking-container">On-Street Parking</div>
              </div>
            )
          } */}
            {parking.length > 0 && parking[0].parking.rateCard && (
              <div>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyPress={accordion}
                  onClick={accordion}
                  className="drive-show-parking"
                >
                  <div className="drive-show-text">More Information</div>
                  <img
                    loading="lazy"
                    src={AccordionIcon}
                    alt="accordion"
                    // className={`${setRotate}`}
                    className={setRotate}
                  />
                </div>
                <div
                  ref={content}
                  style={{ maxHeight: `${setHeight}` }}
                  className="drive-parking-list"
                >
                  {
                    parking[0].parking.rateCard && (
                      <div className="parking-rates">
                        <ReactMarkdown
                          children={parking[0].parking.rateCard}
                          unwrapDisallowed
                          disallowedTypes={['Paragraph']}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={screentype === 'desktop' ? 'journey-details-car-time' : 'journey-details-mobile-car-time'} style={data.realTime ? { backgroundColor: hex2rgb(`#${data.statusColour}`) } : { background: '#FFFFFF' }}>
          <img loading="lazy" src={imgContext(data)} alt="live" className="car-img" />
          <p className="j-car-time" style={data.realTime ? { color: statusCOntext(data) } : { color: '#000000' }}>{functionDuration(data.duration)}</p>
          <p className="j-car-measure" style={data.realTime ? { color: statusCOntext(data) } : { color: '#000000' }}>min</p>
        </div>
      </div>
      <div className="drive-leg-navigation">
        <div className="drive-leg-navigate-text">Navigate With</div>
        <div className="drive-leg-logo-container">
          <div className="drive-logo-container">
            <div role="button" tabIndex={0} className="drive-leg-logo" style={{ margin: '0px 0px 8px 0px' }} onClick={(e) => clickedWaze(e, data)} onKeyPress={(e) => clickedWaze(e, data)}>
              <img src={Waze} alt="waze" />
              <div className="drive-leg-logo-text">Waze</div>
            </div>
            <div role="button" tabIndex={0} className="drive-leg-logo" onClick={(e) => clickedGoogleMap(e, data)} onKeyPress={(e) => clickedGoogleMap(e, data)}>
              <img src={GoogleMapIcon} alt="waze" />
              <div className="drive-leg-logo-text">Google Maps</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DriveLeg.propTypes = {
  /**
      screentype desktop, tablet, mobile
    */
  screentype: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  parking: PropTypes.arrayOf(shape()).isRequired,
  functionDuration: PropTypes.func.isRequired,
  clickedWaze: PropTypes.func.isRequired,
  clickedGoogleMap: PropTypes.func.isRequired,
};
