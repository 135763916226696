/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
// import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import CalendarIcon from './image/light-calendar.svg';
import DarkIcon from './image/dark-calendar.svg';
import './datetimecontainer.css';

export default function DateTimeContainer(props) {
  const { dateTime, setDateTime } = props;

  // console.log('go here datetime container', typeof dateTime);
  const handleChange = (newValue) => {
    setDateTime(newValue);
  };
  return (
    <div className="calendar-container-sd">
      <img loading="lazy" src={DarkIcon} alt="calendar" style={{ margin: '0px 8px 0px 0px' }} />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
        <Stack spacing={3}>
          <MobileDateTimePicker
            // value={dateTime}
            inputFormat="dddd, Do MMM yyyy, hh:mm a"
            InputProps={{
              disableUnderline: true,
            }}
            // sx={{ maxWidth: '100%' }}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                sx={{
                  svg: { display: 'none' },
                  input: {
                    color: 'black',
                    padding: '0px',
                    maxWidth: '100%',
                    fontSize: '14px',
                  },
                  // maxWidth: '100%',
                }}
                variant="standard"
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </div>

  );
}

DateTimeContainer.propTypes = {
  /**
            list of option for autocomplete
        */
  setDateTime: PropTypes.func.isRequired,
  /**
            title text
      */
  dateTime: PropTypes.object.isRequired,
};
