import React from 'react';
import PropTypes from 'prop-types';
import OriginIcon from './image/yellow-origin.svg';
import './journeylistorigin.css';

export default function JourneyListEnd(props) {
  const { landmark, fulladdress } = props;
  // console.log(fulladdress);
  return (
    <div className="jOrigin-container">
      <div className="jOrigin-details">
        <img loading="lazy" src={OriginIcon} alt="details" className="originJourney-img" />
        <div className="jLandmark-origin-container">
          <div className="landmark">{landmark}</div>
          <div className="fulladdress">{fulladdress}</div>
        </div>
      </div>
    </div>
  );
}

JourneyListEnd.propTypes = {
  /**
        to display landmark
    */
  landmark: PropTypes.string,
  /**
        to display address
    */
  fulladdress: PropTypes.string,
};

JourneyListEnd.defaultProps = {
  landmark: 'UOA',
  fulladdress: 'U1, Section, No. 1, Jalan Pengaturcara U1/51a, Kawasan Perindustrian Temasya, 40150 Shah Alam, Selangor',
};
