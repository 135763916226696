import React from 'react';
import PropTypes from 'prop-types';
import './journeyoverviewp.css';

export default function JourneyOverviewP(props) {
  const {
    children, screentype, valPadding, second,
  } = props;

  return (
    <div
      // eslint-disable-next-line no-nested-ternary
      className={screentype === 'desktop' ? 'jdetail-overview-p-container' : second ? 'jdetail-overview-p-mobile-container-second' : 'jdetail-overview-p-mobile-container'}
      style={{ padding: valPadding }}
    >
      { children }
    </div>
  );
}

JourneyOverviewP.propTypes = {
  /**
      list of children
    */
  children: PropTypes.element.isRequired,
  /**
      screentype: desktop, tablet, mobile
    */
  screentype: PropTypes.string.isRequired,
  /**
      add in padding string value
    */
  valPadding: PropTypes.string.isRequired,
  /**
      add in padding string value
    */
  second: PropTypes.bool.isRequired,
};
