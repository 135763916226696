import React from 'react';
import PropTypes from 'prop-types';
import './timedetailduration.css';

export default function TimeDetailDuration(props) {
  const { hour, min } = props;
  return (
    <div className="joverview-detailst-timeduration-container">
      {
        hour > 0 && (
          <div className="joverview-detailst-timeduration-moreoption">
            <div className="joverview-primary">{hour}</div>
            <div className="joverview-secondary">hr</div>
          </div>
        )
      }
      <div className="joverview-detailst-timeduration-moreoption" style={{ margin: '0px 0px 0px 2px' }}>
        <div className="joverview-primary">{min}</div>
        <div className="joverview-secondary">min</div>
      </div>
    </div>
  );
}

TimeDetailDuration.propTypes = {
  /**
        to display hour.
    */
  hour: PropTypes.number,
  /**
        to display min.
    */
  min: PropTypes.number,
};

TimeDetailDuration.defaultProps = {
  hour: null,
  min: null,
};
