import React from 'react';
import PropTypes from 'prop-types';
import './journeyoptionparents.css';

export default function JourneyOptionParentsTwo(props) {
  const { children, screentype } = props;
  return (
    <div className={screentype === 'desktop' ? 'joptioncardtwo-container' : 'joptioncardtwo-mobile-container'}>{children}</div>
  );
}

JourneyOptionParentsTwo.propTypes = {
  /**
    props for children element
      */
  children: PropTypes.element,
  /**
    screentype: desktop, mobile, tablet
      */
  screentype: PropTypes.string.isRequired,
};

JourneyOptionParentsTwo.defaultProps = {
  children: <div />,
};
