import React, { useState, useLayoutEffect } from 'react';

import PropTypes from 'prop-types';
import './drawer.css';

export default function Drawers(props) {
  const [size, setSize] = useState({
    y: window.innerHeight,
  });
  const { open } = props;
  /// --------------this is for responsive height to fixed the empty space in big screen
  useLayoutEffect(() => {
    function updateSize() {
      setSize({
        y: window.innerHeight,
      }); // take the windows height and - 56px. The 56px are height from top bar
    }
    window.addEventListener('resize', updateSize);
    updateSize(); // update the size based on screen change
    return () => window.removeEventListener('resize', updateSize); // return the updated height value, this value will add in div elements for sideBar and Panel
  }, []);
  return (
    <div>
      {
            open && (
            <div className="drawer-container" style={{ height: `${size.y - 44}px` }}>
              <div className="drawer-list">
                <button className="drawer-btn-menu" type="button">Home</button>
                <button className="drawer-btn-menu" type="button">Plan Journey</button>
                <button className="drawer-btn-menu" type="button">Be a Partner</button>
                <button className="drawer-btn-menu" type="button">News</button>
                <button className="drawer-btn-menu" type="button">About Us</button>
                <button className="drawer-btn-menu" type="button" style={{ margin: '0px' }}>
                  Contact Us
                </button>
              </div>
            </div>
            )
        }
    </div>
  );
}

Drawers.propTypes = {
  /**
   state value - boolean
  */
  open: PropTypes.bool,
};

Drawers.defaultProps = {
  open: false,
};
