import React from 'react';
import './price.css';
import PropTypes from 'prop-types';
import Ticket from './image/grey-ticket.svg';

export default function Price(props) {
  const { currency, price } = props;
  return (
    <div>
      <div className="price-container">
        <img loading="lazy" src={Ticket} alt="ticket" />
        <div className="price-detail-container">
          <div className="price-currency">{currency}</div>
          <div className="price-number">{price}</div>
        </div>
      </div>
    </div>
  );
}

Price.propTypes = {
  /**
      value from setState
    */
  currency: PropTypes.string,
  /**
     inline color for radiobutton, use the hex color
   */
  price: PropTypes.string,
};

Price.defaultProps = {
  currency: 'RM',
  price: '8.30',
};
