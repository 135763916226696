/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import * as uuid from 'uuid';
import Desktop from './Desktop';
import Tablet from './Tablet';
import Mobile from './Mobile';
import { APISearchCalled, APIOptionCalled, APISearchSelect } from './ApiCalled';
import useGeoLocation from './useGeolocation';

function App() {
  // original
  // const isDefault = useMediaQuery({ minWidth: 1025 });
  // const isMobile = useMediaQuery({ minHeight: 320, maxWidth: 767 });
  // const isTablet = useMediaQuery({ minHeight: 768, maxWidth: 1024 });
  // new one
  const refForButton = useRef(null);
  const browserUtcOffset = Math.abs(new Date().getTimezoneOffset() / 60);
  const isDefault = useMediaQuery({ minWidth: 1025 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const [open, setOpen] = useState(false); // for open draw
  const [alignment, setAlignment] = useState('left'); // for toggle button
  const [isLoaded, setIsLoaded] = useState(false); // isLoaded from API
  const [itenary, setItenary] = useState([]);
  const [swap, setSwap] = useState(false); // onClick swap button.
  const increment = useRef(null);
  const [valAutocomplete, setValAutocomplete] = useState({
    from: {},
    to: {},
  }); // take autocomplete value
  const [displayRefresh, setDisplayRefresh] = useState(false); // refresh button
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [optionHover, setOptionHover] = useState({ hover: false, index: 0 });
  const [polyLineHover, setPolyLineHover] = useState([]);
  const [singleMode, setSingleMode] = useState([]);
  const [multiMode, setMultiMode] = useState([]);

  const [valFrom, setValFrom] = useState({
    details: '',
    group: '',
    lat: '',
    lng: '',
    name: '',
    type: '',
    placeId: '',
  }); // take autocomplete value FROM
  const [valTo, setValTo] = useState({
    details: '',
    group: '',
    lat: '',
    lng: '',
    name: '',
    type: '',
    placeId: '',
  }); // take autocomplete value TO
  const [resultAPI, setResultAPI] = useState([{
    name: 'Use Current Location',
    details: '',
    lat: '',
    lng: '',
    type: 'CUR',
  }]); // RESULT VALUE FROM API -FROM
  const [resultDetails, setResultDetails] = useState({}); // result for journey details
  const [resultAPITo, setResultAPITo] = useState([
    {
      name: 'Use Current Location',
      details: '',
      lat: '',
      lng: '',
      type: 'CUR',
    },
  ]); // RESULT VALUE FROM API - TO
  const [txtValue, setTxtvalue] = useState({
    name: '',
  }); // from value
  const [txtValueTo, setTxtvalueTo] = useState({
    name: '',
  }); // to value
  const [select, setSelect] = useState({}); // for menu react selection 'leave now'
  const [datetime, setDatetime] = useState(moment().utc(browserUtcOffset).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')); // for date time selection under leave now
  const [displayOp, setDisplayOp] = useState(false); // boolean value to display journey option
  const [displayDet, setDisplayDet] = useState(false); // boolean value to display the journey det
  const [displayMap, setDisplayMap] = useState(false); // boolean value to display map
  const [detParking, setDetParking] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
  const [uuids, setUuids] = useState('');
  const [centerLat, setCenterLat] = useState({});
  const [onLoadCoord, setOnLoadCoord] = useState({});
  const [loadCoordInd, setLoadCoordInd] = useState(false);
  const geoLocation = useGeoLocation(centerLat);
  const [manipulateElement, setManipulateElement] = useState(null);
  const options = [
    { value: 'leave', label: 'Leave Now' },
    { value: 'depart', label: 'Depart At' },
    { value: 'arrive', label: 'Arrive By' },
  ];

  // generate uuid for search api
  useEffect(() => {
    const uuidV4 = uuid.v4();
    setUuids(uuidV4);
  }, []);

  // console.log('refForButton', refForButton);
  // this useEffect to display the autocomplete localstorage.
  useEffect(() => {
    if (localStorage.getItem('autocomplete')) {
      const localS = JSON.parse(localStorage.getItem('autocomplete'));
      const valueDefault = [
        {
          name: 'Use Current Location',
          details: '',
          lat: '',
          lng: '',
          type: 'CUR',
        },
      ];
      const combine = localS.concat(valueDefault);
      const sort = combine.sort((b, a) => (a.group >= b.group ? 1 : -1));
      setResultAPI(sort);
      setResultAPITo(sort);
    }
  }, [valFrom || valTo]);

  // reset timer
  const btnResetTimer = () => {
    clearInterval(increment.current);
    setDisplayRefresh(false);
  };

  // for disabled button
  useEffect(() => {
    if (valFrom.name && valTo.name) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [valFrom, valTo]);

  // first load geolocation
  useEffect(() => {
    if (geoLocation.loaded) {
      const denied = geoLocation.error;
      const notDenied = geoLocation.coordinates;
      // this if the location are turn off
      if (denied) {
        setOnLoadCoord(denied);
        setLoadCoordInd(true);
        // alert(` denied, lat: ${denied.lat} lon: ${denied.lng}`);
      } else { // this if the location are turn on
        setOnLoadCoord(notDenied);
        setLoadCoordInd(false);
        // to update the update location FROM, if select use Current Location
        if (valFrom.name === 'Use Current Location') {
          setValFrom({
            details: '',
            lat: notDenied.lat.toString(),
            lng: notDenied.lng.toString(),
            name: 'Use Current Location',
            type: '',
          });
        }
        // to update the update location TO, if select use Current Location
        if (valTo.name === 'Use Current Location') {
          setValTo({
            details: '',
            lat: notDenied.lat.toString(),
            lng: notDenied.lng.toString(),
            name: 'Use Current Location',
            type: '',
          });
        }
      }
    }
  }, [geoLocation]);

  // if there is on drag
  useEffect(() => {
    if (geoLocation.loaded) {
      if (geoLocation.error) {
        // console.log('error');
        const denied = geoLocation.error;
        setOnLoadCoord(denied);
        setLoadCoordInd(true);
      }
    }
  }, [txtValue.name]);

  useEffect(() => {
    if (geoLocation.loaded) {
      if (geoLocation.error) {
        // console.log('error');
        const denied = geoLocation.error;
        setOnLoadCoord(denied);
        setLoadCoordInd(true);
      }
    }
  }, [txtValueTo.name]);

  // from autocompelete
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout); // this to clear time out
    }
    setSearchTimeout( // to buffer the search by 0.5 second
      setTimeout(() => {
        APISearchCalled(txtValue.name, 'FROM', setResultAPI, setIsLoaded, uuids, onLoadCoord, 'RAIL'); // call the api
      }, 200),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [txtValue.name]);

  // console.log(navigator.geolocation.getCurrentPosition());

  // To autocomplete
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout); // this to clear time out
    }
    setSearchTimeout( // to buffer the search by 0.5 second
      setTimeout(() => {
        APISearchCalled(txtValueTo.name, 'TO', setResultAPITo, setIsLoaded, uuids, onLoadCoord, 'RAIL'); // call the api
      }, 200),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [txtValueTo.name]);

  // function to open the drawer
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (itenary.length > 0) {
      // to check if its multi modes
      const mMode = itenary.filter((x) => x.modeSummary.length > 1);
      // to check single mode
      const sMode = itenary.filter((x) => x.modeSummary.length === 1 && (x.modeSummary.includes('WALK') || x.modeSummary.includes('DRIVE')));
      // to check if single mode if not walk / drive
      const multiModesRailBus = itenary.filter((x) => (x.modeSummary.length === 1) && (x.modeSummary.includes('RAIL') || x.modeSummary.includes('BUS') || x.modeSummary.includes('KNR')));
      // to combine the multiModes and single mode rail / bus
      const combineMode = multiModesRailBus.length > 0
        ? mMode.concat(multiModesRailBus)
          .sort((a, b) => a.modeSummary.length - b.modeSummary.length) : mMode;
      setMultiMode(combineMode);
      setSingleMode(sMode);
    }
  }, [itenary]);

  const journeyDetails = (value) => {
    // taking parking legs for display in parking
    const filterParking = value.legs.filter((x) => x.mode === 'PARK');
    setResultDetails(value);
    setDetParking(filterParking);
    setDisplayDet(true);
    btnResetTimer();
  };

  return (
    <div>
      {isTablet && (
        <Tablet
          setAlignment={setAlignment}
          alignment={alignment}
          isLoaded={isLoaded}
          setSwap={setSwap}
          swap={swap}
          setValAutocomplete={setValAutocomplete}
          valAutocomplete={valAutocomplete}
          setValFrom={setValFrom}
          valFrom={valFrom}
          setValTo={setValTo}
          valTo={valTo}
          setResultAPI={setResultAPI}
          resultAPI={resultAPI}
          setResultAPITo={setResultAPITo}
          resultAPITo={resultAPITo}
          setTxtvalue={setTxtvalue}
          txtValue={txtValue}
          setTxtvalueTo={setTxtvalueTo}
          txtValueTo={txtValueTo}
          setSelect={setSelect}
          select={select}
          setDatetime={setDatetime}
          datetime={datetime}
          setDisplayOp={setDisplayOp}
          displayOp={displayOp}
          setDisplayDet={setDisplayDet}
          displayDet={displayDet}
          setDisplayMap={setDisplayMap}
          displayMap={displayMap}
          handleDrawerOpen={handleDrawerOpen}
          options={options}
          journeyDetails={journeyDetails}
          open={open}
          APIOptionCalled={APIOptionCalled}
          setIsLoaded={setIsLoaded}
          setItenary={setItenary}
          itenary={itenary}
          setDisplayRefresh={setDisplayRefresh}
          displayRefresh={displayRefresh}
          setResultDetails={setResultDetails}
          resultDetails={resultDetails}
          setDisabledBtn={setDisabledBtn}
          disabledBtn={disabledBtn}
          setDetParking={setDetParking}
          detParking={detParking}
          setOptionHover={setOptionHover}
          optionHover={optionHover}
          setPolyLineHover={setPolyLineHover}
          polyLineHover={polyLineHover}
          btnResetTimer={btnResetTimer}
          increment={increment}
          setSingleMode={setSingleMode}
          setMultiMode={setMultiMode}
          singleMode={singleMode}
          multiMode={multiMode}
          setUuid={setUuids}
          uuids={uuids}
          setCenterLat={setCenterLat}
          centerLat={centerLat}
          APISearchSelect={APISearchSelect}
          onLoadCoord={onLoadCoord}
          loadCoordInd={loadCoordInd}
          refForButton={refForButton}
        />
      )}
      {
        isMobile && (
          <Mobile
            setAlignment={setAlignment}
            alignment={alignment}
            isLoaded={isLoaded}
            setSwap={setSwap}
            swap={swap}
            setValAutocomplete={setValAutocomplete}
            valAutocomplete={valAutocomplete}
            setValFrom={setValFrom}
            valFrom={valFrom}
            setValTo={setValTo}
            valTo={valTo}
            setResultAPI={setResultAPI}
            resultAPI={resultAPI}
            setResultAPITo={setResultAPITo}
            resultAPITo={resultAPITo}
            setTxtvalue={setTxtvalue}
            txtValue={txtValue}
            setTxtvalueTo={setTxtvalueTo}
            txtValueTo={txtValueTo}
            setSelect={setSelect}
            select={select}
            setDatetime={setDatetime}
            datetime={datetime}
            setDisplayOp={setDisplayOp}
            displayOp={displayOp}
            setDisplayDet={setDisplayDet}
            displayDet={displayDet}
            setDisplayMap={setDisplayMap}
            displayMap={displayMap}
            handleDrawerOpen={handleDrawerOpen}
            options={options}
            journeyDetails={journeyDetails}
            open={open}
            APIOptionCalled={APIOptionCalled}
            setIsLoaded={setIsLoaded}
            setItenary={setItenary}
            itenary={itenary}
            setDisplayRefresh={setDisplayRefresh}
            displayRefresh={displayRefresh}
            setResultDetails={setResultDetails}
            resultDetails={resultDetails}
            setDisabledBtn={setDisabledBtn}
            disabledBtn={disabledBtn}
            setDetParking={setDetParking}
            detParking={detParking}
            setOptionHover={setOptionHover}
            optionHover={optionHover}
            setPolyLineHover={setPolyLineHover}
            polyLineHover={polyLineHover}
            btnResetTimer={btnResetTimer}
            increment={increment}
            setSingleMode={setSingleMode}
            setMultiMode={setMultiMode}
            singleMode={singleMode}
            multiMode={multiMode}
            setUuid={setUuids}
            uuids={uuids}
            setCenterLat={setCenterLat}
            centerLat={centerLat}
            APISearchSelect={APISearchSelect}
            onLoadCoord={onLoadCoord}
            loadCoordInd={loadCoordInd}
            refForButton={refForButton}
          />
        )
      }
      { isDefault && (
        <Desktop
          setAlignment={setAlignment}
          alignment={alignment}
          isLoaded={isLoaded}
          setSwap={setSwap}
          swap={swap}
          setValAutocomplete={setValAutocomplete}
          valAutocomplete={valAutocomplete}
          setValFrom={setValFrom}
          valFrom={valFrom}
          setValTo={setValTo}
          valTo={valTo}
          setResultAPI={setResultAPI}
          resultAPI={resultAPI}
          setResultAPITo={setResultAPITo}
          resultAPITo={resultAPITo}
          setTxtvalue={setTxtvalue}
          txtValue={txtValue}
          setTxtvalueTo={setTxtvalueTo}
          txtValueTo={txtValueTo}
          setSelect={setSelect}
          select={select}
          setDatetime={setDatetime}
          datetime={datetime}
          setDisplayOp={setDisplayOp}
          displayOp={displayOp}
          setDisplayDet={setDisplayDet}
          displayDet={displayDet}
          setDisplayMap={setDisplayMap}
          displayMap={displayMap}
          handleDrawerOpen={handleDrawerOpen}
          options={options}
          journeyDetails={journeyDetails}
          open={open}
          APIOptionCalled={APIOptionCalled}
          setIsLoaded={setIsLoaded}
          setItenary={setItenary}
          itenary={itenary}
          setDisplayRefresh={setDisplayRefresh}
          displayRefresh={displayRefresh}
          setResultDetails={setResultDetails}
          resultDetails={resultDetails}
          setDisabledBtn={setDisabledBtn}
          disabledBtn={disabledBtn}
          setDetParking={setDetParking}
          detParking={detParking}
          setOptionHover={setOptionHover}
          optionHover={optionHover}
          setPolyLineHover={setPolyLineHover}
          polyLineHover={polyLineHover}
          btnResetTimer={btnResetTimer}
          increment={increment}
          setSingleMode={setSingleMode}
          setMultiMode={setMultiMode}
          singleMode={singleMode}
          multiMode={multiMode}
          setUuid={setUuids}
          uuids={uuids}
          setCenterLat={setCenterLat}
          centerLat={centerLat}
          APISearchSelect={APISearchSelect}
          onLoadCoord={onLoadCoord}
          loadCoordInd={loadCoordInd}
          refForButton={refForButton}
          manipulateElement={manipulateElement}
        />
      ) }
      <style>
        {
          `.app-autocomplete{
            display: grid; 
            grid-auto-columns: 1fr; 
            grid-template-columns: 1fr; 
            grid-template-rows: 1fr 1fr; 
            gap: 8px 4px; 
            grid-template-areas: 
              "."
              "."; 
          }
          .app-layout-bottom{
            display: grid; 
            grid-auto-columns: 1fr; 
            grid-template-columns: 420px 1fr; 
            grid-template-rows: 1fr; 
            gap: 8px 0px; 
            grid-template-areas: 
              ". ."; 
            // overflow: hidden;
          }
          .card-option{
            display: flex;
            margin: 0px 0px 8px 0px;
            padding: 0px 8px 0px 8px;
            display: grid; 
            grid-auto-columns: 1fr; 
            grid-template-columns: 1fr 96px; 
            grid-template-rows: 1fr; 
            gap: 0px 0px; 
            grid-template-areas: 
              ". ."; 
          }
          .card-option-mobile{
            display: flex;
            margin: 0px 0px 8px 0px;
            padding: 0px 8px 0px 8px;
            display: grid; 
            grid-auto-columns: 1fr; 
            grid-template-columns: 1fr 86px; 
            grid-template-rows: 1fr; 
            gap: 0px 0px; 
            grid-template-areas: 
              ". ."; 
          }
          .overview-journey{
            margin: 16px 0px 0px 0px;
            padding: 0px 8px 0px 8px;
            display: grid; 
            grid-auto-columns: 1fr; 
            grid-template-columns: 1fr 96px; 
            grid-template-rows: 1fr; 
            gap: 0px 2px; 
            grid-template-areas: 
              ". ."; 
          }
          .overview-mobile-journey{
            margin: 16px 0px 0px 0px;
            padding: 0px 8px 0px 8px;
            display: grid; 
            grid-auto-columns: 1fr; 
            grid-template-columns: 1fr 86px; 
            grid-template-rows: 1fr; 
            gap: 0px 2px; 
            grid-template-areas: 
              ". ."; 
          }
          .journey-detail-container{
            margin: 8px;
            background-color: #FFFFFF;
            border-radius: 4px;
          }
          .journey-detail-mobile-container{
            margin: 0px 8px 8px 8px;
            background-color: #FFFFFF;
            border-radius: 4px;
          }
          // .overview-journey-detail-scroll{
          //   overflow: scroll;
          //   -ms-overflow-style: none;
          //   scrollbar-width: none;
          // }
          // .overview-journey-detail-scroll::-webkit-scrollbar {
          //   display: none;
          // }
          `
        }
      </style>
    </div>
  );
}

export default App;
