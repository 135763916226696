import React from 'react';
import PropTypes from 'prop-types';
import OriginIcon from './image/origin.svg';
import './destinationleg.css';

export default function DestinationLeg(props) {
  const { screentype, landmark, fulladdress } = props;
  return (
    <div className={screentype === 'desktop' ? 'journey-details-destination-leg-container' : 'journey-details-mobile-destination-leg-container'}>
      <img loading="lazy" src={OriginIcon} alt="walk" />
      <div className="journey-details-destination-stop-container">
        <div className="destination-stop">
          <p className="get-destination">Arrive at</p>
          <p className="destination-stop-container">{landmark}</p>
        </div>
        <div className="details-destination-address">
          {fulladdress}
        </div>
      </div>
    </div>
  );
}

DestinationLeg.propTypes = {
  /**
      screentype desktop, tablet, mobile
    */
  screentype: PropTypes.string.isRequired,
  landmark: PropTypes.string.isRequired,
  fulladdress: PropTypes.string.isRequired,
};
