import React from 'react';
import PropTypes from 'prop-types';
import LightMapIcon from './image/light-map.svg';
import './jdetailshowmap.css';

export default function JdetailShowButton(props) {
  const { text, setMapOp } = props;
  const btnSearch = () => {
    // alert('btn search clicked');
    setMapOp(true);
  };
  return (
    <button type="button" className="btn-show-map" onClick={() => btnSearch()}>
      <img loading="lazy" src={LightMapIcon} alt="alt" style={{ margin: '0px 4px 0px 0px' }} />
      {' '}
      {text}
    </button>
  );
}

JdetailShowButton.propTypes = {
  /**
        to display text inside button
    */
  text: PropTypes.string,
  setMapOp: PropTypes.bool.isRequired,
  // autoValue: PropTypes.objectOf(value).isRequired,
};

JdetailShowButton.defaultProps = {
  text: 'GO',
};
