import React from 'react';
import PropTypes from 'prop-types';
import WalkingIcon from './image/walking.svg';
import './walkleg.css';

export default function WalkLeg(props) {
  const {
    screentype, data, functionDuration,
  } = props;

  // console.log('walkLeg', data);
  return (
    <div className={screentype === 'desktop' ? 'journey-details-walk-leg-container' : 'journey-details-mobile-walk-leg-container'}>
      <div className="journey-details-walk-detail">
        <img loading="lazy" src={WalkingIcon} alt="walk" />
        <div className="journey-details-walk-stop-container">
          <p className="walk-to">Walk to</p>
          {/* eslint-disable-next-line no-nested-ternary */}
          {data.to.nextLegPreview && data.to.nextLegPreview.mode === 'RAIL'
            ? (
              <div
                className="routeid-container-rail"
                style={{
                  backgroundColor: `#${data.to.nextLegPreview.routeColor}`,
                  color: `#${data.to.nextLegPreview.routeTextColor}`,
                }}
              >
                {data.to.nextLegPreview.routeShortName
                  ? data.to.nextLegPreview.routeShortName
                  : data.to.nextLegPreview.routeId}
              </div>
            )
            : data.to.nextLegPreview ? <div className="routeid-container">{data.to.nextLegPreview.routeShortName ? data.to.nextLegPreview.routeShortName : data.to.nextLegPreview.routeId}</div> : null}

          <p className="stop-name-container" style={{ color: data.to.nextLegPreview && `#${data.to.nextLegPreview.routeColor}` }}>{data.to.name}</p>
          {
            data.to.vertexType !== 'NORMAL' && (
              <p className="walk-to">
                {data.to.nextLegPreview.mode === 'RAIL' ? 'Station' : 'Bus Station'}
              </p>
            )
          }
        </div>
      </div>
      <div className="journey-details-walk-time">
        <p className="j-time">{functionDuration(data.duration)}</p>
        <p className="j-measure">min</p>
      </div>
    </div>
  );
}

WalkLeg.propTypes = {
  /**
      screentype desktop, tablet, mobile
    */
  screentype: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  functionDuration: PropTypes.func.isRequired,
};
