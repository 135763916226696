/* eslint-disable prefer-const */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import * as uuid from 'uuid';
// import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import CurrentIcon from './image/current-location.svg';
import BusIcon from './image/grey-bus.svg';
import LocationIcon from './image/grey-location.svg';
import TransitIcon from './image/grey-transit.svg';
import ParkingIcon from './image/grey-parking.svg';
import ToIcon from './image/orange-search.svg';
import FromIcon from './image/yellow-search.svg';
import './autocomplete.css';

/**
 * list key:
 * name - this to label
 * address - this for address
 */

const useStyles = makeStyles(() => ({
  inputRoot: {
    backgroundColor: 'white',
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
  },
}));

export default function Autocompletes(props) {
  const {
    list, text, setTxtvalue, txtValue, isLoaded, setValAutocomplete, setResult, valAutocomplete,
    setUuid, APISearchSelect, uuids, onLoadCoord, loadCoordInd,
  } = props;
  const classes = useStyles();
  // img render
  const imgRender = (option) => {
    switch (option.type) {
      case 'CUR':
        return CurrentIcon;
      case 'POI':
        return LocationIcon;
      case 'BUS':
        return BusIcon;
      case 'TRAIN':
        return TransitIcon;
      case 'RAIL':
        return TransitIcon;
      case 'PARKING':
        return ParkingIcon;
      default:
        return '';
    }
  };
  // get geolocation function
  const getGeolocation = () => {
    setValAutocomplete({
      name: 'Use Current Location',
      details: '',
      lat: onLoadCoord.lat.toString(),
      lng: onLoadCoord.lng.toString(),
      type: '',
    });
    setTxtvalue({ name: 'Use Current Location' });

    // #region to getCurrentPosition event handler
    // navigator.geolocation.getCurrentPosition((position) => {
    //   // add in set state position.coords.longitude and position.coords.latitude
    //   setValAutocomplete({
    //     name: 'Use Current Location',
    //     details: '',
    //     lat: position.coords.latitude.toString(),
    //     lng: position.coords.longitude.toString(),
    //     type: '',
    //   });
    //   setTxtvalue({ name: 'Use Current Location' });
    // });
    // #endregion
  };

  // onChange from Autocomplete
  const onUserInput = async (e, value) => {
    const uuidV4 = uuid.v4();
    if (value) {
      if (value.type === 'CUR') { // it will go to getGeolocation function and get the current position of lon and lng
        getGeolocation();
      } else if (value.type === 'POI') {
        const latlng = await APISearchSelect(uuids, value.placeId);
        if (latlng.status === 'OK') {
          const emptyObj = {
            group: value.group,
            lat: latlng.data.lat,
            lng: latlng.data.lng,
            name: value.name,
            placeId: value.placeId,
            sort: value.sort,
            type: value.type,
            details: value.details,
          };
          // this to check if have local storage
          if (localStorage.getItem('autocomplete')) {
            // console.log('if ada localstorage have go here');

            const localStorageArray = JSON.parse(localStorage.getItem('autocomplete'));
            localStorageArray.push(emptyObj);
            const removeDuplicate = localStorageArray
              .filter((thing, index, self) => index === self.findIndex((t) => (
                t.name === thing.name && t.details === thing.details
              )));
            localStorage.setItem('autocomplete', JSON.stringify(removeDuplicate)); // remove the duplicate
          } else {
            localStorage.setItem('autocomplete', JSON.stringify([emptyObj])); // add in new one in localStorage
          }
          setValAutocomplete(emptyObj); // take value from API
          setTxtvalue({ name: value.name });
        }
      } else {
        if (localStorage.getItem('autocomplete')) {
          // console.log('if ada localstorage have go here');
          const localStorageArray = JSON.parse(localStorage.getItem('autocomplete'));
          localStorageArray.push(value);
          const removeDuplicate = localStorageArray
            .filter((thing, index, self) => index === self.findIndex((t) => (
              t.name === thing.name && t.details === thing.details
            )));
          localStorage.setItem('autocomplete', JSON.stringify(removeDuplicate));
        } else {
          localStorage.setItem('autocomplete', JSON.stringify([value]));
        }
        setValAutocomplete(value); // take value from API
        setTxtvalue({ name: value.name });
      }
    }
    setUuid(uuidV4);
  };

  // onChange from textfield
  const onUserInputTxt = (e) => {
    setTxtvalue({ name: e.target.value });
    if (!e.target.value) {
      setValAutocomplete({
        details: '',
        group: '',
        lat: '',
        lng: '',
        name: '',
        type: '',
      });
    }
  };

  const imgInputAutocomplete = (typeText) => {
    switch (typeText) {
      case 'From':
        return FromIcon;
      case 'To':
        return ToIcon;
      default:
        return FromIcon;
    }
  };

  const groupType = (group) => {
    switch (group) {
      case 'POI':
        return 'Google Places';
      case 'STOPS':
        return 'Stop & Station';
      case 'PARKING':
        return 'Parking';
      default:
        return '';
    }
  };

  const clearIndicatorMUI = () => {
    if (localStorage.getItem('autocomplete')) {
      const localS = JSON.parse(localStorage.getItem('autocomplete'));
      const geoLocation = [{
        name: 'Use Current Location',
        details: '',
        lat: '',
        lng: '',
        type: 'CUR',
      }];
      const combine = localS.concat(geoLocation);
      const sort = combine.sort((b, a) => (a.group >= b.group ? 1 : -1));
      setResult(sort);
      setValAutocomplete({
        details: '',
        group: '',
        lat: '',
        lng: '',
        name: '',
        type: '',
      });
    } else {
      const geoLocation = [{
        name: 'Use Current Location',
        details: '',
        lat: '',
        lng: '',
        type: 'CUR',
      }];
      setResult(geoLocation);
      setValAutocomplete({
        details: '',
        group: '',
        lat: '',
        lng: '',
        name: '',
        type: '',
      });
    }
    setTxtvalue({ name: '' });
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <div>
      <Stack spacing={2}>
        <Autocomplete
          fullWidth
          size="small"
          classes={classes}
          options={list}
          loading={isLoaded}
          inputValue={txtValue.name}
          value={valAutocomplete.name}
          filterOptions={(x) => x}
          getOptionLabel={(option) => option.name || ''}
          groupBy={(option) => groupType(option.group)}
          onChange={(e, value) => onUserInput(e, value)}
          sx={{ maxWidth: '100%' }}
          defaultValue={valAutocomplete.name}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === 'clear') {
              clearIndicatorMUI();
            }
          }}
          renderOption={(prop, option) => (
            <li style={{ padding: '12px 8px' }} {...prop}>
              <img loading="lazy" src={imgRender(option)} alt="curIcon" />
              <div className="autocomplete-lable-list">
                <div className="autocomplete-option-label">{option.name}</div>
                <div className="autocomplete-option-address">{option.details}</div>
              </div>
            </li>
          )}
          renderInput={(params) => (
            <div ref={params.InputProps.ref} className="autocomplete-input-label">
              <img loading="lazy" src={imgInputAutocomplete(text)} alt="alt" className="autocomplete-img" />
              <TextField
                {...params}
                // value={txtValue.name}
                placeholder={text}
                inputProps={{
                  ...params.inputProps,
                  style: { paddingLeft: '27px', position: 'relative', top: '1px' },
                }}
                onChange={(e) => onUserInputTxt(e)}
              />
              {/* <input
                type="text"
                name="firstName"
                value={txtValue.name}
                onChange={(e) => onUserInputTxt(e)}
              /> */}
            </div>
          )}
        />
      </Stack>
    </div>
  );
}
/**
  list of option
 */
const listObject = PropTypes.shape({
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  address: PropTypes.string,
});

Autocompletes.propTypes = {
  /**
    list of option for autocomplete
  */
  list: PropTypes.arrayOf(listObject).isRequired,
  /**
          title text
    */
  text: PropTypes.string,
  /**
    setState for input string
  */
  setTxtvalue: PropTypes.string.isRequired,
  txtValue: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool,
  setValAutocomplete: PropTypes.func.isRequired,
  setResult: PropTypes.func.isRequired,
  valAutocomplete: PropTypes.shape().isRequired,
  setUuid: PropTypes.func.isRequired,
  APISearchSelect: PropTypes.func.isRequired,
  uuids: PropTypes.string.isRequired,
  onLoadCoord: PropTypes.shape().isRequired,
  loadCoordInd: PropTypes.bool.isRequired,
};

Autocompletes.defaultProps = {
  text: 'From',
  isLoaded: false,
};
