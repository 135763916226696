import React, { useState, useLayoutEffect } from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import PropTypes from 'prop-types';
import './sidebar.css';

const disclaimer = 'Hello! It looks like you’ve stumbled upon the demo showcase site for Trek, our Multimodal Journey Planner built upon Trek API and our proprietary Mobiliti Engine for intelligent journey planning. Transit data such as prices, service availability and scheduling, may change unexpectedly at any time. Asia Mobiliti does not make any warranties about the completeness, reliability and accuracy of this information, and hence will not be liable for any losses and/or damages in connection with the use of this site.';

export default function Sidebar(props) {
  const { children, screentype, refForButton } = props;
  const [size, setSize] = useState({
    y: window.innerHeight,
    x: window.innerWidth,
  });
  /// --------------this is for responsive height to fixed the empty space in big screen
  useLayoutEffect(() => {
    function updateSize() {
      setSize({
        y: window.innerHeight,
        x: window.innerWidth,
      }); // take the windows height and - 56px. The 56px are height from top bar
    }
    window.addEventListener('resize', updateSize);
    updateSize(); // update the size based on screen change
    return () => window.removeEventListener('resize', updateSize); // return the updated height value, this value will add in div elements for sideBar and Panel
  }, []);

  const sideBarType = (type) => {
    switch (type) {
      case 'desktop':
        return (
          <div className="sidebar-desktop-container" style={{ minHeight: `${size.y - 44}px` }}>
            <div className="sidebar-desktop-children">
              {children}
              <div className="sidebar-disclaimer-container">
                {/* <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                  <div ref={refForButton} />
                </div> */}

                <div className="sidebar-disclaimer-title">
                  <WarningAmberRoundedIcon sx={{
                    width: '9px', height: '9px', position: 'relative', top: '1px', margin: '0px 4px 0px 0px',
                  }}
                  />
                  Disclaimer
                </div>
                {disclaimer}
              </div>
            </div>
          </div>
        );
      case 'tablet':
        return (
          <div className="sidebar-mobile-container" style={{ minHeight: `${size.y}px` }}>
            <div className="sidebar-desktop-children">
              {children}
              <div className="sidebar-disclaimer-container">
                <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                  <div ref={refForButton} />
                </div>
                <div className="sidebar-disclaimer-title">
                  <WarningAmberRoundedIcon sx={{
                    width: '9px', height: '9px', position: 'relative', top: '1px', margin: '0px 4px 0px 0px',
                  }}
                  />
                  Disclaimer
                </div>
                {disclaimer}
              </div>
            </div>
          </div>
        );
      case 'mobile':
        return (
          <div className="sidebar-mobile-container" style={{ minHeight: `${size.y}px`, width: `${size.x}px` }}>
            <div className="sidebar-desktop-children">
              {children}
              <div className="sidebar-disclaimer-container">
                <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                  <div ref={refForButton} />
                </div>
                <div className="sidebar-disclaimer-title">
                  <WarningAmberRoundedIcon sx={{
                    width: '9px', height: '9px', position: 'relative', top: '1px', margin: '0px 4px 0px 0px',
                  }}
                  />
                  Disclaimer
                </div>
                {disclaimer}
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="sidebar-desktop-container" style={{ minHeight: size.y }}>
            <div className="sidebar-desktop-children">
              {children}
              <div className="sidebar-disclaimer-container">
                <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                  <div ref={refForButton} />
                </div>
                <div className="sidebar-disclaimer-title">
                  <WarningAmberRoundedIcon sx={{
                    width: '9px', height: '9px', position: 'relative', top: '1px', margin: '0px 4px 0px 0px',
                  }}
                  />
                  Disclaimer
                </div>
                {disclaimer}
              </div>
            </div>
          </div>
        );
    }
  };
  return (
    <div>
      {sideBarType(screentype)}
    </div>
  );
}

Sidebar.propTypes = {
  /**
   the value use for screentype are "desktop", "mobile" and "tablet"
  */
  screentype: PropTypes.string.isRequired,
  /**
   children element, able to add in the html element
  */
  children: PropTypes.element,
  refForButton: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  children: <div />,
};
