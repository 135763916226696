import React from 'react';
import PropTypes from 'prop-types';

import './swapbutton.css';
import SwapIcon from './image/dark-swap.svg';

export default function Swapbutton(props) {
  const { onClick } = props;
  return (
    <button type="button" className="btn-swap" onClick={onClick}>
      <img loading="lazy" src={SwapIcon} alt="swap-icon" />
    </button>

  );
}

Swapbutton.propTypes = {
  /**
    function that do the swapping
  */
  onClick: PropTypes.func.isRequired,
};
