import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Polyline,
} from '@react-google-maps/api';

export default function DetailsModeDetails(props) {
  const { data, polylineDecode, lineSymbol } = props;
  const [walk, setWalk] = useState([]);
  const [trail, setTrail] = useState([]);
  const [bus, setBus] = useState([]);
  const [drive, setDrive] = useState([]);
  const [knr, setKnr] = useState([]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const mWalk = data.legs.filter((x) => x.mode === 'WALK');
      const mTrail = data.legs.filter((x) => x.mode === 'RAIL');
      const mBus = data.legs.filter((x) => x.mode === 'BUS');
      const mDrive = data.legs.filter((x) => x.mode === 'DRIVE');
      const mKnr = data.legs.filter((x) => x.mode === 'KNR');
      setWalk(mWalk);
      setTrail(mTrail);
      setBus(mBus);
      setDrive(mDrive);
      setKnr(mKnr);
    } else {
      setWalk([]);
      setTrail([]);
      setBus([]);
      setDrive([]);
      setKnr([]);
    }
  }, [data]);

  return (
    <div>
      {/* {WALK mode} */}
      {
            walk.length > 0 && walk.map((x) => (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 0,
                    strokeWeight: 5,
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
        }
      {/* {RAIL mode} */}
      {
            trail.length > 0 && trail.map((x) => (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: `#${x.routeColor}`,
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
        }
      {/* {DRIVE mode} */}
      {
            drive.length > 0 && drive.map((x) => (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
        }
      {/* {BUS mode} */}
      {
            bus.length > 0 && bus.map((x) => (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: '#128110',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
        }
      {/* {KNR mode} */}
      {
            knr.length > 0 && knr.map((x) => (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
        }
    </div>
  );
}

DetailsModeDetails.propTypes = {
  /**
              to display time.
    */
  data: PropTypes.shape().isRequired,
  polylineDecode: PropTypes.func.isRequired,
  lineSymbol: PropTypes.func.isRequired,
};
