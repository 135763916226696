import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import TrekIcon from './image/trekIcon.svg';
import MenuIcons from './image/menu.svg';
import CloseIcon from './image/close.svg';

import './appbar.css';

export default function AppMenu(props) {
  const { screentype, handleDrawerOpen, open } = props;
  // const btnNoti = (x) => {
  //   if (x === 'bells') {
  //     alert('notification alert');
  //   }
  //   if (x === 'mail') {
  //     alert('mail alert');
  //   }
  //   if (x === 'personal') {
  //     alert('profile alert');
  //   }
  // };

  return (
    <div>
      {
                // eslint-disable-next-line no-nested-ternary
                screentype === 'desktop'
                  ? (
                    <Box sx={{ flexGrow: 1, flexDirection: 'column' }}>
                      <AppBar
                        position="fixed"
                        sx={{
                          zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#FFFFFF', height: '44px',
                        }}
                      >
                        <div className="appbar-desktop">
                          <img loading="lazy" src={TrekIcon} alt="trek-icon" />
                          <div className="appbar-desktop-menu-container">
                            {/* <button className="appbar-btn-menu"
                            type="button">Plan Journey</button>
                            <button className="appbar-btn-menu" type="button">Be a Partner</button>
                            <button className="appbar-btn-menu" type="button">News</button>
                            <button className="appbar-btn-menu" type="button">About Us</button>
                            <button className="appbar-btn-menu"
                            type="button" style={{ margin: '0px' }}>Contact Us</button> */}
                          </div>
                        </div>

                      </AppBar>
                    </Box>
                  ) : screentype === 'mobile' || screentype === 'tablet' ? (
                    <Box sx={{ display: 'flex' }}>
                      <CssBaseline />
                      <AppBar
                        position="fixed"
                        sx={{
                          zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#FFFFFF', height: '44px', display: 'flex',
                        }}
                      >
                        {/* <Toolbar sx={{ position: 'absolute' }}> */}
                        <div className="appbar-mobile">
                          {/* <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ color: '#939598', margin: '0px 16px 0px 0px', padding: '0px' }}
                          >
                            <img loading="lazy" src={open ? CloseIcon : MenuIcons} alt="menu" />
                          </IconButton> */}
                          <img loading="lazy" src={TrekIcon} alt="trek-icon" />
                        </div>
                        {/* </Toolbar> */}
                      </AppBar>
                    </Box>
                  ) : (
                    <Box sx={{ flexGrow: 1, flexDirection: 'column' }}>
                      <AppBar
                        position="fixed"
                        sx={{
                          zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#FFFFFF', height: '44px',
                        }}
                      >
                        <div className="appbar-desktop">
                          <img loading="lazy" src={TrekIcon} alt="trek-icon" />
                          <div className="appbar-desktop-menu-container">
                            <button className="appbar-btn-menu" type="button">Plan Journey</button>
                            <button className="appbar-btn-menu" type="button">Be a Partner</button>
                            <button className="appbar-btn-menu" type="button">News</button>
                            <button className="appbar-btn-menu" type="button">About Us</button>
                            <button className="appbar-btn-menu" type="button" style={{ margin: '0px' }}>Contact Us</button>
                          </div>
                        </div>

                      </AppBar>
                    </Box>
                  )
            }
    </div>
  );
}

// const colorIndicator = {
//   planJourney: PropTypes.bool,
//   partner: PropTypes.bool,
//   news: PropTypes.bool,
//   aboutUs: PropTypes.bool,
//   contactUs: PropTypes.bool,
// };

AppMenu.propTypes = {
  /**
   the default value for screentype are "desktop", "mobile" and "tablet"
  */
  screentype: PropTypes.string.isRequired,
  /**
   function when click handleDrawer, this function are outside, and inside
   function will have state to indicates open and close drawer
  */
  handleDrawerOpen: PropTypes.func,
  open: PropTypes.bool,
};

AppMenu.defaultProps = {
  handleDrawerOpen: () => {},
  open: false,
};
