import React from 'react';
import PropTypes from 'prop-types';
import './timeduration.css';

export default function TimeDuration(props) {
  const { hour, min, screentype } = props;
  return (
    <div className={screentype === 'desktop' ? 'time-duration-container' : 'time-duration-mobile-container'}>
      <div className="duration">
        {
          hour > 0 && (
            <div className="timeduration-display-container" style={{ margin: '0px 4px 0px 0px' }}>
              <div className="number" style={{ margin: '0px 4px 0px 0px' }}>{hour}</div>
              <div className="text">hr</div>
            </div>
          )
        }
        <div className="timeduration-display-container">
          <div className="number" style={{ margin: '0px 4px 0px 0px' }}>{min}</div>
          <div className="text">min</div>
        </div>
      </div>
    </div>
  );
}

TimeDuration.propTypes = {
  /**
        to display hour.
    */
  hour: PropTypes.number,
  /**
        to display min.
    */
  min: PropTypes.number,
  /**
        screentype: desktop, tablet, mobile
    */
  screentype: PropTypes.string.isRequired,
};

TimeDuration.defaultProps = {
  hour: null,
  min: null,
};
