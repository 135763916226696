import React from 'react';
import PropTypes from 'prop-types';
import EndIcon from './image/orange-end.svg';
import './journeylistend.css';

export default function JourneyListOrigin(props) {
  const { landmark, fulladdress } = props;
  return (
    <div className="jend-container">
      <div className="jend-details">
        <img loading="lazy" src={EndIcon} alt="end-icon" className="endJourney-img" />
        <div className="jLandmark-end-container">
          <div className="end-landmark">{landmark}</div>
          <div className="end-fulladdress">{fulladdress}</div>
        </div>
      </div>
    </div>
  );
}

JourneyListOrigin.propTypes = {
  /**
        to display landmark
    */
  landmark: PropTypes.string,
  /**
        to display fulladdress
    */
  fulladdress: PropTypes.string,
};

JourneyListOrigin.defaultProps = {
  landmark: 'UOA Business Park',
  fulladdress: 'U1, Section, No. 1, Jalan Pengaturcara U1/51a, Kawasan Perindustrian Temasya, 40150 Shah Alam, Selangor',
};
