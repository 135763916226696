import React from 'react';
import PropTypes from 'prop-types';
import LightButtonIcon from './image/light-backbutton.svg';
import DarkButtonIcon from './image/dark-backbutton.svg';

export default function Backbutton(props) {
  const { onClick } = props;
  return (
    <input type="image" src={DarkButtonIcon} onClick={onClick} alt="backbutton" />
  );
}

Backbutton.propTypes = {
  /**
        onClick event for back button
    */
  onClick: PropTypes.func.isRequired,
};
