import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Car from './image/dark-car.svg';
import Bus from './image/dark-bus.svg';
import Train from './image/train.svg';
import Park from './image/parking.svg';
import GreyChevron from './image/grey-chevron.svg';
import DropOff from './image/dark-dropoff.svg';
import './multimodel.css';

export default function Multimodel(props) {
  const [newData, setNewData] = useState([]);
  const { data } = props;

  // console.log('multimodal', data);

  useEffect(() => {
    if (data.length > 0) {
      const filterWalk = data.filter((x) => x.mode !== 'WALK'); // this to filter walk
      setNewData(filterWalk);
    }
  }, []);
  // this to check the modes
  const settingMode = (x) => {
    switch (x.mode) {
      case 'DRIVE':
        return (
          <div className="joption-car-container">
            <img loading="lazy" src={Car} alt="car" />
          </div>
        );
      case 'RAIL':
        return (
          <div className="joption-train-container">
            <img loading="lazy" src={Train} alt="train" />
            <div className="joption-text-container" style={{ border: `0.5px solid #${x.routeColor}`, backgroundColor: `#${x.routeColor}` }}>{x.routeShortName ? x.routeShortName : x.routeId}</div>
          </div>
        );
      case 'TRAIN':
        return (
          <div className="joption-train-container">
            <img loading="lazy" src={Train} alt="train" />
            <div className="joption-text-container" style={{ border: `0.5px solid #${x.routeColor}`, backgroundColor: `#${x.routeColor}` }}>{x.routeShortName ? x.routeShortName : x.routeId}</div>
          </div>
        );
      case 'PARK':
        return (
          <div className="joption-park-container">
            <img loading="lazy" src={Park} alt="park" />
          </div>
        );
      case 'BUS':
        return (
          <div className="joption-bus-container">
            <img loading="lazy" src={Bus} alt="bus" />
            <div className="joption-bus">{x.routeShortName ? x.routeShortName : x.routeId}</div>
          </div>
        );
      case 'KNR':
        return (
          <div className="joption-dropoff-container" style={{ height: 20, width: 20 }}>
            <img loading="lazy" src={DropOff} alt="bus" />
          </div>
        );
      default:
        return (
          <div />
        );
    }
  };

  return (
    <div className="joption-arrow-container">
      {
        newData.length > 0 && newData.map((x, i, arr) => (arr.length - 1 === i ? (
          <div className="joption-arrow">
            {settingMode(x)}
          </div>
        ) : (
          <div className="joption-arrow">
            {settingMode(x)}
            <img
              loading="lazy"
              src={GreyChevron}
              alt="chev"
              style={{ margin: '0px 2px 0px 2px' }}
            />
          </div>
        )))
      }
    </div>
  );
}

Multimodel.propTypes = {
  /**
        list of data modes
    */
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};
