/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './menu.css';
// import LightScheduleIcon from './image/light-schedule.svg';
import DarkScheduleIcon from './image/dark-schedule.svg';

export default function Menu(props) {
  const {
    options, setSelect, select, screen,
  } = props;
  return (
    <div>
      <div className="mmenu-hexcode-container">
        <div className="mmenu-hexcode-number">&#9314;</div>
        <div className="mmenu-dash-submite"> &#8212; </div>
        <div style={{ fontSize: '14px' }}>When?</div>
      </div>
      <div className="menu-container">
        <img loading="lazy" src={DarkScheduleIcon} alt="icon" />
        <Select
          options={options}
          defaultValue={Object.keys(select) <= 0 ? options[0] : select}
          isSearchable={false}
          onChange={(e) => setSelect(e)}
        />
      </div>
      {/* this is temporary */}
      <style>
        {/* {
            `.css-1okebmr-indicatorSeparator{
                width: 0px;
            }
            .css-1s2u09g-control{
                border-style: none;
                background-color: transparent;
            }

            .css-1pahdxg-control{
                border-style:none;
                box-shadow: 0 0 0 0px ;
            }
            .css-qc6sy-singleValue{
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-size: 14px;
                // color: #FFFFFF;
                color: black;
            }
            .css-1pahdxg-control{
               background-color: transparent;
            }
            .css-319lph-ValueContainer {
              padding: 2px 6px;
            }
            `
          } */}
        {
          `.css-1u9des2-indicatorSeparator {
                            width: 0px;
                        }
                        .css-13cymwt-control{
                            border-style: none;
                        }
                        
                        .css-t3ipsp-control{
                            border-style:none;
                            box-shadow: 0 0 0 0px ;
                        }
                        .css-1dimb5e-singleValue{
                            font-weight: 600;
                        }
                        `
        }
      </style>
    </div>
  );
}

const listOption = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

Menu.propTypes = {
  /**
        list of menu options
    */
  options: PropTypes.arrayOf(listOption).isRequired,
  setSelect: PropTypes.func.isRequired,
  select: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  screen: PropTypes.string.isRequired,
};
