/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TrainIcon from './image/train.svg';
import WaitIcon from './image/wait.svg';
import ExitIcon from './image/exit-stop.svg';
import KJL from './image/KJL.svg';
import AGL from './image/AGL.svg';
import BRT from './image/BRT.svg';
import KLIA from './image/KLIA.svg';
import MRL from './image/MRL.svg';
import PKG from './image/PKG.svg';
import SBK from './image/SBK.svg';
import SPL from './image/SPL.svg';
import SRB from './image/SRB.svg';
import TRS from './image/TRS.svg';
import AccordionIcon from './image/accordion.svg';
import './trainleg.css';

export default function TrainLeg(props) {
  const { screentype, data, functionDuration } = props;
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('train_accordion__icon_group_status');
  const [setActiveStop, setActiveStateStop] = useState('');
  const [setHeightStop, setHeightStateStop] = useState('0px');
  const [setRotateStop, setRotateStateStop] = useState('train_accordion__icon_group_status');

  const content = useRef(null);
  const contentTwo = useRef(null);

  // console.log('trainLeg', data);
  const accordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`,
    );
    setRotateState(
      setActive === 'active' ? 'train_accordion__icon_group_status' : 'train_accordion__icon_group_status train-rotate',
    );
  };

  const scheduleIn = (depature, arrival) => {
    const diffDepature = (depature - arrival) / 1000;
    return functionDuration(diffDepature);
  };

  const accordionTwo = () => {
    setActiveStateStop(setActiveStop === '' ? 'active' : '');
    setHeightStateStop(
      setActiveStop === 'active' ? '0px' : `${contentTwo.current.scrollHeight}px`,
    );
    setRotateStateStop(
      setActiveStop === 'active' ? 'train_accordion__icon_group_status' : 'train_accordion__icon_group_status train-rotate',
    );
  };

  const railIcon = (route) => {
    switch (route) {
      case 'KJL':
        return KJL;
      case 'AGL':
        return AGL;
      case 'BRT':
        return BRT;
      case 'MRL':
        return MRL;
      case 'PKG':
        return PKG;
      case 'SBK':
        return SBK;
      case 'SPL':
        return SPL;
      case 'SRB':
        return SRB;
      case 'TRS':
        return TRS;
      default:
        return KLIA;
    }
  };
  return (
    <div className={screentype === 'desktop' ? 'journey-detail-train-container' : 'journey-detail-mobile-train-container'}>
      <div className="timeline-middle">
        <img loading="lazy" src={WaitIcon} alt="wait" className="timeline-point" />
      </div>
      <div className="timeline-component">
        <div className="train-platform">
          <div className="train-platform-detail">
            <p style={{
              margin: '0px 4px 0px 0px', fontWeight: 'normal', fontSize: '12px', lineHeight: '17px',
            }}
            >
              Wait for
            </p>
            <div
              style={{
                backgroundColor: `#${data.routeColor}`,
                color: `#${data.routeTextColor}`,
              }}
              className="j-train-route-detail"
            >
              {data.routeShortName ? data.routeShortName : data.routeId}
            </div>
            <p style={{
              margin: '0px', fontWeight: 'bold', color: `#${data.routeColor}`, fontSize: '12px', lineHeight: '17px',
            }}
            >
              {data.route}
            </p>
          </div>
          {
            data.from.platformCode && (
              <p
                style={{ margin: '0px' }}
                className="j-train-platform-display"
              >
                {' '}
                Platform
                {' '}
                {data.from.platformCode}
              </p>
            )
          }
          <div style={{ height: '21px' }} />
          {/* <div
            role="button"
            tabIndex={0}
            onKeyPress={accordion}
            onClick={accordion}
            className="train-schedule-perform"
          >
            <div className="schedule-color">Schedule</div>
            <img loading="lazy" src={AccordionIcon} alt="style" className={`${setRotate}`} />
          </div> */}
          {/* <div
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
            className="train-schedule-list"
          >
            <div>hello workd</div>
          </div> */}
        </div>
        <div className="train-container">
          <div className="train-container-first">Scheduled in</div>
          <div className="train-container-second">
            <div className="train-container-second-number">{scheduleIn(data.from.departure, data.from.arrival)}</div>
            <div className="train-container-second-measure">min</div>
          </div>
        </div>
      </div>
      <div className="timeline-middle-sd">
        <img loading="lazy" src={TrainIcon} alt="wait" className="timeline-point-sd" />
      </div>
      <div className="timeline-component">
        <div className="train-platform">
          <div className="train-platform-detail">
            <p style={{
              margin: '0px 4px 0px 0px', fontWeight: 'normal', fontSize: '12px', lineHeight: '17px',
            }}
            >
              Travel to
            </p>
            <div
              style={{
                backgroundColor: `#${data.routeColor}`,
                color: `#${data.routeTextColor}`,
              }}
              className="j-train-route-detail"
            >
              {data.routeShortName ? data.routeShortName : data.routeId}
            </div>
            <p style={{
              margin: '0px', color: `#${data.routeColor}`, fontSize: '12px', fontWeight: 'bold', lineHeight: '17px',
            }}
            >
              {data.to.name}
            </p>
          </div>
          <div className="journey-detail-train-display">
            <div className="timeline-middle-stops" style={{ borderColor: `#${data.routeColor}` }}>
              <img loading="lazy" src={railIcon(data.routeShortName ? data.routeShortName : data.routeId)} alt="wait" className="timeline-point-fr" />
            </div>
            <div className="timeline-component-sd">
              <div className="stopid-name-container">
                {data.from.name}
              </div>
              {data.intermediateStops && data.intermediateStops.length > 1 && (
                <div role="button" tabIndex={0} onKeyPress={accordionTwo} onClick={accordionTwo} className="train-schedule-perform">
                  <div className="schedule-color">
                    {data.intermediateStops && data.intermediateStops.length}
                    {' '}
                    stops
                  </div>
                  {/* <img loading="lazy" src={AccordionIcon} alt="style" className={`${setRotateStop}`} /> */}
                  <img loading="lazy" src={AccordionIcon} alt="style" className={setRotateStop} />
                </div>
              )}
              <div
                ref={contentTwo}
                style={{ maxHeight: `${setHeightStop}` }}
                className="train-stop-list"
              >
                {
                  data.intermediateStops && data.intermediateStops.length > 0
                  && data.intermediateStops.map((x, i) => (
                    <div>
                      <div className="timeline-stop-middle-sd-fr" style={{ borderColor: '#E0115F' }}>
                        {setActiveStop === 'active' && (
                          <img
                            loading="lazy"
                            src={railIcon(data.routeShortName ? data.routeShortName : data.routeId)}
                            alt="wait"
                            className="timeline-point-fr-sd-rf"
                          />
                        )}
                      </div>
                      <div className="timeline-component-sd-fr">
                        <div className={setActiveStop === 'active'
                          ? 'stopid-name-container-fr text-nothidden'
                          : 'stopid-name-container-fr text-hidden'}
                        >
                          {x.name}
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="timeline-stop-middle-sd" style={{ borderColor: '#E0115F' }}>
              <img loading="lazy" src={railIcon(data.routeShortName ? data.routeShortName : data.routeId)} alt="wait" className="timeline-point-fr-sd" />
            </div>
            <div className="timeline-component-sd">
              <div className="stopid-name-container">
                {data.to.name}
              </div>
              {/* {
                data.to.platformCode && (
                  <div className="exit-img-container">
                    <img loading="lazy" src={ExitIcon} alt="exit" />
                    <div className="exit-container">
                      Exit at Platform
                      {' '}
                      {data.to.platformCode}
                    </div>
                  </div>
                )
              } */}
            </div>
          </div>
        </div>
        <div className="train-container-stop-name">
          <div className="train-container-second-one">
            <div className="train-container-second-one-number">{functionDuration(data.duration)}</div>
            <div className="train-container-second-one-measure">min</div>
          </div>

        </div>
      </div>
    </div>
  );
}

TrainLeg.propTypes = {
  /**
      screentype desktop, tablet, mobile
    */
  screentype: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  functionDuration: PropTypes.func.isRequired,
};
