import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Polyline,
} from '@react-google-maps/api';

export default function OnHoverMode(props) {
  const {
    data, polylineDecode, optionHover,
  } = props;
  const [walk, setWalk] = useState([]);
  const [trail, setTrail] = useState([]);
  const [bus, setBus] = useState([]);
  const [drive, setDrive] = useState([]);
  const [knr, setKnr] = useState([]);

  useEffect(() => {
    if (optionHover) {
      const mWalk = data.filter((x) => x.mode === 'WALK');
      const mTrail = data.filter((x) => x.mode === 'RAIL');
      const mBus = data.filter((x) => x.mode === 'BUS');
      const mDrive = data.filter((x) => x.mode === 'DRIVE');
      const mKnr = data.filter((x) => x.mode === 'KNR');
      setWalk(mWalk);
      setTrail(mTrail);
      setBus(mBus);
      setDrive(mDrive);
      setKnr(mKnr);
    } else {
      setWalk([]);
      setTrail([]);
      setBus([]);
      setDrive([]);
      setKnr([]);
    }
  }, [data]);

  const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeWeight: 4,
    strokeOpacity: 1,
    scale: 3,
  };
  return (
    <div>
      {
            optionHover.hover && data.length > 0 && data.map((x) => x.mode === 'WALK' && (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 0,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 0,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
     }
      {
            optionHover.hover && data.length > 0 && data.map((x) => x.mode === 'DRIVE' && (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
     }
      {
            optionHover.hover && data.length > 0 && data.map((x) => x.mode === 'RAIL' && (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: `#${x.routeColor}`,
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
     }
      {
            optionHover.hover && data.length > 0 && data.map((x) => x.mode === 'BUS' && (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: '#128110',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
     }
      {
            optionHover.hover && data.length > 0 && data.map((x) => x.mode === 'KNR' && (
              x.legGeometry && x.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(x.legGeometry.points)}
                  options={{
                    strokeColor: '#128110',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                <Polyline
                  path={[{ lat: x.from.lat, lng: x.from.lon },
                    { lat: x.to.lat, lng: x.to.lon }]}
                  options={{
                    strokeColor: '#128110',
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    zIndex: 1,
                    icons: [
                      {
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              )
            ))
     }
    </div>
  );
}

OnHoverMode.propTypes = {
  /**
                to display time.
      */
  data: PropTypes.shape().isRequired,
  polylineDecode: PropTypes.func.isRequired,
  optionHover: PropTypes.bool.isRequired,
};
