/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './journeycarddepature.css';

export default function JourneyCardDepature(props) {
  const { type, stops } = props;
  const [newData, setNewData] = useState([]);
  const [minutes, setMinutes] = useState('');

  useEffect(() => {
    if (stops.length > 0) {
      const filterWalk = stops.filter((x) => x.mode !== 'WALK');
      const deptNumber = parseInt(filterWalk[0].from.departure);
      const currentTimestamp = moment().valueOf();
      const timeCurrent = moment(currentTimestamp).format('DD/MM/YYYY hh:mm:ss');
      const timeAPI = moment(deptNumber).format('DD/MM/YYYY hh:mm:ss');
      const diff = moment(timeAPI, 'DD/MM/YYYY HH:mm:ss').diff(moment(timeCurrent, 'DD/MM/YYYY HH:mm:ss'));
      const duration = moment.duration(diff);
      // eslint-disable-next-line no-underscore-dangle
      const negativeNumber = duration._data.minutes < 0 ? 0 : duration._data.minutes;
      // console.log(minute);
      // console.log('journeycard number', deptNumber);
      setNewData(filterWalk);
      setMinutes(negativeNumber);
    }
  }, []);

  // newData.length > 0 && console.log(moment(newData[0].from.duration).format('mm'));

  const modeType = (mode) => {
    switch (mode) {
      case 'DRIVE':
        return 'Leave Immediately';
      default:
        return `Depart in ${minutes && minutes} min`;
    }
  };
  return (
    <div className="jcard-depature-container">
      <div className="jcard-depature-mode">{modeType(type)}</div>
      <div className="jcard-depature-stops">
        {type !== 'DRIVE' && ` from ${newData.length > 0 && newData[0].from.name}`}
      </div>
    </div>
  );
}

JourneyCardDepature.propTypes = {
  /**
      props for children element
        */
  type: PropTypes.string,
  stops: PropTypes.string,
};

JourneyCardDepature.defaultProps = {
  type: '',
  stops: '',
};
