import React from 'react';
import PropTypes from 'prop-types';
import './travelmode.css';

export default function Travelmode(props) {
  const { text } = props;
  return (
    <div className="mode-parent-container">
      <div className="mode-parent-text">{text}</div>
    </div>
  );
}

Travelmode.propTypes = {
  /**
   display text title
  */
  text: PropTypes.string,
};

Travelmode.defaultProps = {
  text: 'Travel Mode',
};
