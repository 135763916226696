/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
const combinationType = (stopType) => {
  switch (stopType) {
    case 'POI':
      return 'POI';
    case 'BUS':
      return 'STOPS';
    case 'RAIL':
      return 'STOPS';
    case 'TRAIN':
      return 'STOPS ';
    case 'PARKING':
      return 'PARKING';
    default:
      return '';
  }
};

const sorting = (stopType) => {
  switch (stopType) {
    case 'POI':
      return 'A';
    case 'BUS':
      return 'C';
    case 'RAIL':
      return 'C';
    case 'TRAIN':
      return 'C ';
    case 'PARKING':
      return 'B';
    default:
      return '';
  }
};

const requestOptions = {
  method: 'GET',
  headers: {
    'x-api-key': 'v0yAQyqnuMxAi5NLaQ5HG6qfzj06IBDd',
  },
};

const displayURL = (select, DestCoords, isDriving, isStop, OriginCoords, TimeISO) => {
  switch (select.value) {
    case 'depart':
      return `https://apidev.trekapp.com/plan/v1/getItinerary?destinationCoordinate=${DestCoords}&isDriving=${isDriving}&isStopToStop=${isStop}&originCoordinate=${OriginCoords}&departureDateTime=${TimeISO}`;
    case 'arrive':
      return `https://apidev.trekapp.com/plan/v1/getItinerary?destinationCoordinate=${DestCoords}&isDriving=${isDriving}&isStopToStop=${isStop}&originCoordinate=${OriginCoords}&arrivalDateTime=${TimeISO}`;
    default:
      return `https://apidev.trekapp.com/plan/v1/getItinerary?destinationCoordinate=${DestCoords}&isDriving=${isDriving}&isStopToStop=${isStop}&originCoordinate=${OriginCoords}`;
  }
};

export const APISearchCalled = (
  search, text, setState, setIsLoaded, uuids, onLoadCoord, transitMode,
) => {
  // fetch(`https://apidev.trekapp.com/plan/v1/search?searchString=${search}&type=${text}&sessionToken=${uuids}&searchLocation=3.1613277475429418,101.75069660744933&transitMode=${transitMode}&nearMeMode=${transitMode}`,
  setIsLoaded(true);
  fetch(`https://apidev.trekapp.com/plan/v1/search?searchString=${search}&type=${text}&sessionToken=${uuids}&searchLocation=3.1613277475429418,101.75069660744933`,
    requestOptions)
    .then((res) => res.json())
    .then(
      (data) => {
        if (data.status === 'OK' && search) { // this to check if status okay and text search have valuew
          if (text === 'FROM') {
            const { places, transit } = data.searchResult; // take the places and transit result
            // console.log('places', places);
            // console.log('transit', transit);
            const merge = places.concat(transit); // merge both of them
            // to remove if there is undefined value.
            const mergeFilter = merge.filter((x) => x !== undefined);
            // reducer (if the data display no type and add in group for easy sorting)
            const option = mergeFilter.map((x) => ({
              name: x.name,
              details: x.details,
              lat: x.lat,
              lng: x.lng,
              type: x.type ? x.type : 'POI',
              group: combinationType(x.type),
              sort: sorting(x.type),
              placeId: x.placeId,
            }));
            // sort the option for group labeling
            const sort = option.sort((b, a) => (a.group >= b.group ? 1 : -1));
            setState(sort); // add in the sort value in setState
            setIsLoaded(false); // done loading
          } else {
            const {
              places,
              transit, parking,
            } = data.searchResult; // take the places and transit result
            const mergePtoT = places.concat(transit); // merge both of them
            const mergeParking = mergePtoT.concat(parking);
            // to remove if there is undefined value.
            const mergeFilter = mergeParking.filter((x) => x !== undefined);
            // reducer (if the data display no type and add in group for easy sorting)
            const option = mergeFilter.map((x) => ({
              name: x.name,
              details: x.details,
              lat: x.lat,
              lng: x.lng,
              type: x.type ? x.type : 'POI',
              group: combinationType(x.type),
              sort: sorting(x.type),
              placeId: x.placeId,
            }));
            // sort the option for group labeling
            const sort = option.sort((b, a) => (a.group >= b.group ? 1 : -1));
            setState(sort); // add in the sort value in setState
            setIsLoaded(false); // done loading
          }
        } else { // else setState will go to default value
          if (localStorage.getItem('autocomplete')) {
            const localS = JSON.parse(localStorage.getItem('autocomplete'));
            const geoLocations = [{
              name: 'Use Current Location',
              details: '',
              lat: '',
              lng: '',
              type: 'CUR',
            }];
            const combine = localS.concat(geoLocations);
            const sort = combine.sort((b, a) => (a.group >= b.group ? 1 : -1));
            setState(sort);
          } else {
            setState([{
              name: 'Use Current Location',
              details: '',
              lat: '',
              lng: '',
              type: 'CUR',
            }]);
          }
          setIsLoaded(false); // done loading
        }
      },
    )
    .catch((error) => {
      console.log('searchError', error);
      // the setState will go to default value if there is an error
      if (localStorage.getItem('autocomplete')) {
        const localS = JSON.parse(localStorage.getItem('autocomplete'));
        const geoLocations = [{
          name: 'Use Current Location',
          details: '',
          lat: '',
          lng: '',
          type: 'CUR',
        }];
        const combine = localS.concat(geoLocations);
        const sort = combine.sort((b, a) => (a.group >= b.group ? 1 : -1));
        setState(sort);
      } else {
        setState([{
          name: 'Use Current Location',
          details: '',
          lat: '',
          lng: '',
          type: 'CUR',
        }]);
      }
      setIsLoaded(false); // done loading
    });
};

export const APIOptionCalled = (isDriving,
  isStop, OriginCoords, DestCoords, setItenary, setIsLoaded, TimeISO, select) => {
  const url = displayURL(select, DestCoords, isDriving, isStop, OriginCoords, TimeISO);
  setIsLoaded(true);
  fetch(url, requestOptions)
    .then((res) => res.json())
    .then(
      (data) => {
        // console.log('data.statuis', data);
        if (data.status === 'OK') {
          setItenary(data.itineraries);
          setIsLoaded(false);
        }
      },
      (error) => {
        console.log('error', error);
        setIsLoaded(false);
      },
    );
};

export const APISearchSelect = async (sessionToken, placeId) => {
  const selection = fetch(`https://apidev.trekapp.com/plan/v1/searchSelect?placeId=${placeId}&sessionToken=${sessionToken}`, requestOptions)
    .then((res) => res.json())
    .then(
      (data) => data,
      (error) => {
        console.log(error);
      },
    );

  return selection;
};

export const zipaboutCalled = async (legsValue, uuids, reference) => {
  try {
    const { zpbt } = window;
    console.log('zpbt', zpbt.serviceObject);
    zpbt.serviceObject.create(uuids);
    legsValue.map((x) => {
      zpbt.serviceObject.addService(uuids, 'gtfs', x.mode, x.from, x.to, true, true, {
        time: x.date, // Local time of departure/arrival (inISO 8601 standard)
        origin: x.from, // Origin code
        destination: x.to, // Destination code
        custom_data: {}, // Custom data related to the service (e.g. service ID,operator etc
      });
    });
    zpbt.serviceObject.commit(uuids);
    zpbt.serviceObject.renderButton(uuids, reference.current, 'Keep Me Updated!', null, null, ['#f16022', '#f16022'], '#FFFFFF', 'large', 'test_button', true, ['facebook'], null, null);
  } catch (err) {
    console.log('error from zipabout function', err);
  }
};
