/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';

const useGeoLocation = (value) => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: '', lng: '' },
  });

  const onSuccess = (locations) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: locations.coords.latitude,
        lng: locations.coords.longitude,
      },
    });
  };
  const option = {
    enableHighAccuracy: true,
    maximumAge: 250,
  };
  const onError = (error) => {
    if (Object.keys(value).length <= 0) {
      setLocation({
        loaded: true,
        error: {
          lat: 3.1390,
          lng: 101.6869,
        },
      });
    } else {
      setLocation({
        loaded: true,
        error: {
          lat: value.lat,
          lng: value.lng,
        },
      });
    }
  };

  // to set the geolocation using current position when first load
  // not applicable using watchPosition due to the call back. in useEffect
  const setGeolocationFirst = () => {
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  };

  // function to get the location by using watchPosition callback
  const setGeolocationWatch = () => {
    const geolocation = window.navigator.geolocation.watchPosition(onSuccess, onError, option);
    setTimeout(() => {
      navigator.geolocation.clearWatch(geolocation);
    }, 5000);
  };
  // this for callback location
  useEffect(() => {
    if (!('geolocation' in navigator)) {
      onError();
    }
    // interval to call the watchPosition
    setInterval(() => {
      setGeolocationWatch();
    }, 300000); // set for 5 minute for now.
  }, []);

  // this for first time load
  useEffect(() => {
    if (!('geolocation' in navigator)) {
      onError();
    }
    setGeolocationFirst();
  }, []);

  return location;
};

export default useGeoLocation;
