/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef } from 'react';
import PropTypes, { shape } from 'prop-types';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { Appbar } from './trek-web-component/ui/header-appbar/index';
import { Sidebar } from './trek-web-component/ui/sidebar-parent/index';
import { Toggle } from './trek-web-component/ui/driving-toggle-button/index';
import { AutocompleteParent } from './trek-web-component/ui/autocomplete-parent/index';
import { Autocompletes } from './trek-web-component/ui/geo-autocomplete/index';
import { Swapbutton } from './trek-web-component/ui/swapbutton/index';
import { SubmitParent } from './trek-web-component/ui/home-submit-parent/index';
import { Menu } from './trek-web-component/ui/depature-menu/index';
import { Searchbutton } from './trek-web-component/ui/search-button/index';
// import { Preferencebutton } from './trek-web-component/ui/preference-button/index';
import { Map } from './trek-web-component/ui/map/index';
import { DateTimeContainer } from './trek-web-component/ui/date-time/index';
import { JourneyOptionParent } from './trek-web-component/ui/journey-option-parent/index';
import { JourneyAddress } from './trek-web-component/ui/journey-address-parent/index';
import { Backbutton } from './trek-web-component/ui/journeyaddress-backbutton/index';
import { JourneyListOrigin } from './trek-web-component/ui/journey-list-origin/index';
import { JourneyListEnd } from './trek-web-component/ui/journey-list-end/index';
import { JOptionCardParentOne } from './trek-web-component/ui/journey-op-card-pone/index';
import { JourneyOptionParentsTwo } from './trek-web-component/ui/journey-op-card-ptwo/index';
import { JourneyCardDepature } from './trek-web-component/ui/journey-option-card-depature/index';
import { Multimodel } from './trek-web-component/ui/multi-model/index';
import { Price } from './trek-web-component/ui/price-tag/index';
import { TimeDuration } from './trek-web-component/ui/time-duration/index';
import { ModesDuration } from './trek-web-component/ui/modes-duration/index';
import { Travelmode } from './trek-web-component/ui/slider-card-parent/index';
import { SingleMode } from './trek-web-component/ui/single-mode/index';
import { JourneyOverviewP } from './trek-web-component/ui/journey-detail-over-parent';
import { MultiModelIcon } from './trek-web-component/ui/multi-model-only-icon/index';
import { TimeDetailDuration } from './trek-web-component/ui/time-details-duration/index';
// import { JdetailShowButton } from './trek-web-component/ui/jdetail-show-map-button/index';
import { WalkLeg } from './trek-web-component/ui/journey-detail-walk-leg/index';
import { Divider } from './trek-web-component/ui/journey-detail-divider/index';
import { DropoffLeg } from './trek-web-component/ui/journey-detail-dropoff-leg/index';
import { DestinationLeg } from './trek-web-component/ui/journey-detail-destination-leg/index';
import { DriveLeg } from './trek-web-component/ui/journey-detail-drive-leg/index';
import { TrainLeg } from './trek-web-component/ui/journey-detail-train-leg/index';
import { BusLeg } from './trek-web-component/ui/journey-detail-bus-leg/index';
import { Loaders } from './trek-web-component/ui/loaders/index';
import { RefreshButton } from './trek-web-component/ui/refresh-button/index';
import AMLogo from './img/AMLogo.png';
import { zipaboutCalled } from './ApiCalled';
// import ClickOutside from './ClickOutside';

momentDurationFormatSetup(moment);
function Desktop(props) {
  const {
    setAlignment,
    alignment,
    isLoaded,
    setSwap,
    swap,
    setValFrom,
    valFrom,
    setValTo,
    valTo,
    setResultAPI,
    resultAPI,
    setResultAPITo,
    resultAPITo,
    setTxtvalue,
    txtValue,
    setTxtvalueTo,
    txtValueTo,
    setSelect,
    select,
    setDatetime,
    datetime,
    setDisplayOp,
    displayOp,
    setDisplayDet,
    displayDet,
    options,
    journeyDetails,
    open,
    APIOptionCalled,
    setItenary,
    itenary,
    setIsLoaded,
    setDisplayRefresh,
    displayRefresh,
    resultDetails,
    setResultDetails,
    disabledBtn,
    setDetParking,
    detParking,
    setOptionHover,
    optionHover,
    setPolyLineHover,
    polyLineHover,
    btnResetTimer,
    increment,
    singleMode,
    multiMode,
    setSingleMode,
    setMultiMode,
    setUuid,
    uuids,
    setCenterLat,
    centerLat,
    APISearchSelect,
    onLoadCoord,
    loadCoordInd,
    refForButton,
    manipulateElement,
  } = props;
  const reference = useRef(null);
  const browserUtcOffset = Math.abs(new Date().getTimezoneOffset() / 60);
  const swapButton = () => {
    setSwap(!swap);
    const from = valFrom;
    const to = valTo;
    setValFrom(to);
    setValTo(from);
    setTxtvalueTo(txtValue);
    setTxtvalue(txtValueTo);
  };

  const refreshTimer = () => {
    increment.current = setInterval(() => {
      setDisplayRefresh(true);
    }, 300000); // 5 minute
  };

  const btnSearch = async () => {
    const isDriving = alignment === 'right'; // this to indicate if driving option
    const isStop = valFrom.group === 'STOPS' && valTo.group === 'STOPS'; // this to indicate if stop option
    const OriginCoords = `${valFrom.lat}, ${valFrom.lng}`; // parameter for Origin Coords
    const DestCoords = `${valTo.lat},${valTo.lng}`; // parameter for DestCoords
    const TimeISO = moment(datetime).utc(browserUtcOffset).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    APIOptionCalled(isDriving, isStop, OriginCoords,
      DestCoords, setItenary, setIsLoaded, TimeISO, select); // to call option API
    refreshTimer();
    setDisplayOp(true);
  };

  const btnBack = async () => {
    if (displayDet) {
      setDisplayDet(false);
      setResultDetails({});
      setDisplayRefresh(false);
      btnResetTimer();
      refreshTimer();
      setDetParking([]);
      setPolyLineHover([]);
    } else {
      setDisplayOp(false);
      setDisplayDet(false);
      setItenary([]);
      setDisplayRefresh(false);
      setResultDetails({});
      setPolyLineHover([]);
      setSingleMode([]);
      setMultiMode([]);
      btnResetTimer();
    }
  };

  // useEffect(() => {
  //   if (displayDet) {
  //     const { zpbt } = window;
  //     zpbt.serviceObject.create(uuids);
  //     zpbt.serviceObject.addService(uuids, 'gtfs', 'bus', '1000016', '1000027', true, true, {
  //       time: '2024-01-12T19:10:00+01:00', // Local time of departure/arrival (inISO 8601 standard)
  //       origin: '1000016', // Origin code
  //       destination: '1000027', // Destination code
  //       custom_data: {}, // Custom data related to the service (e.g. service ID,operator etc
  //     });
  //     zpbt.serviceObject.commit(uuids);
  //     zpbt.serviceObject.renderButton(uuids, reference.current, 'Keep Me Updated!', null, null, ['#f16022', '#f16022'], '#FFFFFF', 'large', 'test_button', true, ['facebook'], null, null);
  //   }
  // }, [displayDet]);

  // const regexStopId = () => {
  //   try {

  //   } catch
  // }

  const takeValueAfterSemiColon = (value) => {
    const regex = /:(.*)/;
    const removed = value.match(regex);
    const trim = removed[1].trim();
    return trim;
  };

  useEffect(() => {
    try {
      if (Object.keys(resultDetails).length > 0) {
        const transitModes = ['RAIL', 'BUS', 'TRAIN'];
        const checkIfHaveTransit = resultDetails.legs.length > 0 ? resultDetails.legs.some((x) => transitModes.includes(x.mode)) : false;
        // console.log('checkIfHaveTransit', checkIfHaveTransit);
        if (checkIfHaveTransit) {
          const transitLegOnly = resultDetails.legs.filter((x) => transitModes.includes(x.mode));
          const valueFromTo = transitLegOnly.map((x) => {
            return {
              date: typeof x.startTime === 'string' ? moment(parseInt(x.startTime, 10)).format() : moment(x.startTime).format(),
              from: takeValueAfterSemiColon(x.from.stopId),
              to: takeValueAfterSemiColon(x.to.stopId),
              mode: x.mode,
            };
          });
          if (valueFromTo.length > 0) {
            zipaboutCalled(valueFromTo, uuids, reference);
          }
        }
      }
    } catch (err) {
      console.log('error from useEffect, resultDetails', err);
    }
  }, [resultDetails]);
  // to convert seconds to minute
  const functionDuration = (value) => {
    const duration = moment.duration(value, 'seconds');
    const formatted = duration.format('m');
    return formatted;
  };

  // to convert seperate minute and hour
  const functionMinHourDuration = (value, isHour) => {
    const hours = Math.floor(value / 3600); // convert to hour
    const minutes = Math.floor((value - (hours * 3600)) / 60); // convert to minute
    if (isHour) {
      return hours;
    }
    return minutes;
  };

  const checkAlignment = (value) => { // check if the option card are driving or transit
    if (value.includes('DRIVE') || value.includes('KNR')) {
      return 'DRIVE';
    }
    return 'TRANSIT';
  };

  // price converter.
  const priceConverter = (value) => {
    const realPrice = value / 100;
    const roundedString = realPrice.toFixed(2);
    return roundedString;
  };

  // function for refresh button
  const btnRefresh = async () => {
    const isDriving = alignment === 'right'; // this to indicate if its driving option
    const isStop = valFrom.group === 'STOPS' && valTo.group === 'STOPS'; // this to indicate if the selected route are stop
    const OriginCoords = `${valFrom.lat}, ${valFrom.lng}`; // lat lon parameter for Origin Coords
    const DestCoords = `${valTo.lat},${valTo.lng}`; // lat lon parameter for Dest Coords
    setItenary([]);
    setSingleMode([]);
    setMultiMode([]);
    const TimeISO = moment(datetime).utc(browserUtcOffset).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    APIOptionCalled(isDriving, isStop, OriginCoords,
      DestCoords, setItenary, setIsLoaded, TimeISO, select); // to call option API
    setDisplayRefresh(false);
    btnResetTimer();
    refreshTimer(); // refresh back time out
  };

  // mouse Enter for onHover polygon
  const functionMouseEnter = (x, i) => {
    setOptionHover({ hover: true, index: i });
    setPolyLineHover(x.legs);
  };

  // mouse leave for onHover polygon
  const functionMouseLeave = (x, i) => {
    setOptionHover({ hover: false, index: i });
    setPolyLineHover([]);
  };

  const clickedWaze = (e, value) => {
    e.preventDefault();
    const from = { lat: value.from.lat, lng: value.from.lon };
    const to = { lat: value.to.lat, lng: value.to.lon };
    const url = `https://www.waze.com/live-map/directions?navigate=yes&from=ll.${from.lat}%2C${from.lng}&to=ll.${to.lat}%2C${to.lng}`;
    window.open(url, '_blank');
  };

  const clickedGoogleMap = (e, value) => {
    e.preventDefault();
    const from = { lat: value.from.lat, lng: value.from.lon };
    const to = { lat: value.to.lat, lng: value.to.lon };
    const url = `https://www.google.com/maps/dir/?api=1&origin=${from.lat},${from.lng}&destination=${to.lat},${to.lng}&travelmode=driving`;
    window.open(url, '_blank');
  };

  // card legs mode
  const cardMode = (x) => {
    switch (x.mode) {
      case 'DRIVE':
        return (
          <DriveLeg screentype="desktop" data={x} parking={detParking} functionDuration={functionDuration} clickedWaze={clickedWaze} clickedGoogleMap={clickedGoogleMap} />
        );
      case 'RAIL':
        return (
          <TrainLeg screentype="desktop" data={x} functionDuration={functionDuration} />
        );
      case 'TRAIN':
        return (
          <TrainLeg screentype="desktop" data={x} functionDuration={functionDuration} />
        );
      case 'BUS':
        return (
          <BusLeg screentype="desktop" data={x} functionDuration={functionDuration} />
        );
      case 'KNR':
        return (
          <DropoffLeg screentype="desktop" data={x} functionDuration={functionDuration} />
        );
      case 'WALK':
        return (
          <WalkLeg screentype="desktop" data={x} functionDuration={functionDuration} />
        );
      default:
        return null;
    }
  };

  // console.log('resultDetails', resultDetails);

  return (
    <div>
      <Appbar screentype="desktop" open={open} />
      <div className="app-layout-bottom">
        {
            displayOp ? (
              <JourneyOptionParent screentype="desktop">
                {/* */}
                <div style={{
                  margin: '0px 0px 16px 0px', position: 'sticky', top: 0, zIndex: 2,
                }}
                >
                  <JourneyAddress screentype="desktop">
                    <Backbutton onClick={() => btnBack()} />
                    <div>
                      <JourneyListOrigin
                        landmark={valFrom.name}
                        fulladdress={valFrom.details}
                      />
                      <JourneyListEnd
                        landmark={valTo.name}
                        fulladdress={valTo.details}
                      />
                    </div>
                  </JourneyAddress>
                </div>
                {
                  displayDet ? (
                    <div>
                      <div
                        style={{
                          marginLeft: 8,
                          marginRight: 8,
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                        ref={reference}
                      />
                      <div className="overview-journey">
                        <JourneyOverviewP screentype="desktop" valPadding="8px 16px">
                          <div>
                            <MultiModelIcon data={resultDetails.legs} />
                            {
                              resultDetails.fare && resultDetails.fare.fare && resultDetails.fare.fare.regular && (
                                <div style={{ margin: '4px 0px 0px 0px' }}>
                                  <Price
                                    currency="RM"
                                    price={priceConverter(resultDetails.fare.fare.regular.cents)}
                                  />
                                </div>
                              )
                            }
                          </div>
                        </JourneyOverviewP>
                        <JourneyOverviewP valPadding="18px 12px" second>
                          <TimeDetailDuration
                            hour={functionMinHourDuration(resultDetails.duration, true)}
                            min={functionMinHourDuration(resultDetails.duration, false)}
                          />
                        </JourneyOverviewP>
                      </div>
                      <div className="journey-detail-container">
                        {
                           Object.keys(resultDetails).length > 0
                           && resultDetails.legs.length > 0
                           && resultDetails.legs.map((x) => (
                             <div>
                               {cardMode(x)}
                               {x.mode !== 'PARK' && <Divider />}
                             </div>
                           ))
                         }
                        <DestinationLeg landmark={valTo.name} fulladdress={valTo.details} screentype="desktop" />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Loaders color="#F16022" loading={isLoaded} />
                      {displayRefresh && (
                      <RefreshButton
                        btnRefresh={() => btnRefresh()}
                        screentype="desktop"
                      />
                      )}
                      {
                        multiMode.length > 0 && multiMode.map((x, i) => (
                          <div
                            className="card-option"
                            role="button"
                            onKeyPress={() => journeyDetails(x)}
                            onClick={() => journeyDetails(x)}
                            tabIndex={i}
                            onMouseEnter={() => functionMouseEnter(x, i)}
                            onMouseLeave={() => functionMouseLeave(x, i)}
                          >
                            <JOptionCardParentOne screentype="desktop">
                              <div style={{ margin: '0px 0px 4px 0px' }}>
                                <JourneyCardDepature
                                  type={checkAlignment(x.modeSummary)}
                                  stops={x.legs}
                                />
                              </div>
                              <div style={{ margin: '0px 0px 4px 0px' }}>
                                <Multimodel data={x.legs} />
                              </div>
                              {
                                x.fare && x.fare.fare && x.fare.fare.regular && (
                                  <div style={{ margin: '4px 0px 0px 0px' }}>
                                    <Price
                                      currency="RM"
                                      price={priceConverter(x.fare.fare.regular.cents)}
                                    />
                                  </div>
                                )
                              }
                            </JOptionCardParentOne>
                            <JourneyOptionParentsTwo screentype="desktop">
                              <TimeDuration
                                hour={functionMinHourDuration(x.duration, true)}
                                min={functionMinHourDuration(x.duration, false)}
                                screentype="desktop"
                              />
                              <div style={{ margin: '4px 0px 0px 0px' }}>
                                <ModesDuration
                                  walk={functionDuration(x.walkTime)}
                                  overall={functionDuration(x.waitingTime)}
                                  screentype="desktop"
                                />
                              </div>
                            </JourneyOptionParentsTwo>
                          </div>
                        ))
                      }
                      {!isLoaded && singleMode.length > 0 && (
                      <div style={{ margin: '8px 0px 8px 8px' }}>
                        <Travelmode text="More Options" />
                      </div>
                      )}
                      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
                        {
                        singleMode.length > 0 && singleMode.map((x, i) => (
                          <div>
                            <div
                              role="button"
                              style={{ margin: '0px 8px 0px 8px', cursor: 'pointer' }}
                              onKeyPress={() => journeyDetails(x)}
                              onClick={() => journeyDetails(x)}
                              onMouseEnter={() => functionMouseEnter(x, i)}
                              onMouseLeave={() => functionMouseLeave(x, i)}
                              tabIndex={i}
                            >
                              <SingleMode
                                modeType={x.modeSummary[0]}
                                hour={functionMinHourDuration(x.duration, true)}
                                min={functionMinHourDuration(x.duration, false)}
                                screentype="desktop"
                              />
                            </div>
                          </div>
                        ))
                      }
                      </div>

                    </div>
                  )
                }

              </JourneyOptionParent>
            ) : (
              <Sidebar screentype="desktop" refForButton={refForButton}>
                <div
                  style={{
                    fontSize: '18px', fontStyle: 'normal', fontWeight: 700, color: '#30638e', padding: '24px 24px 0px 24px',
                  }}
                >
                  Plan Journey
                </div>
                <Toggle alignment={alignment} setAlignment={setAlignment} displayImg screentype="desktop" />
                <AutocompleteParent text="Where to?" screentype="desktop">
                  <div className="app-autocomplete">
                    <Autocompletes
                      list={resultAPI}
                      txtValue={txtValue}
                      isLoaded={isLoaded}
                      text="From"
                      setTxtvalue={setTxtvalue}
                      setValAutocomplete={setValFrom}
                      setResult={setResultAPI}
                      valAutocomplete={valFrom}
                      setUuid={setUuid}
                      uuids={uuids}
                      APISearchSelect={APISearchSelect}
                      onLoadCoord={onLoadCoord}
                      loadCoordInd={loadCoordInd}
                    />
                    <Autocompletes
                      list={resultAPITo}
                      txtValue={txtValueTo}
                      isLoaded={isLoaded}
                      text="To"
                      setTxtvalue={setTxtvalueTo}
                      setValAutocomplete={setValTo}
                      setResult={setResultAPITo}
                      valAutocomplete={valTo}
                      setUuid={setUuid}
                      uuids={uuids}
                      APISearchSelect={APISearchSelect}
                      onLoadCoord={onLoadCoord}
                      loadCoordInd={loadCoordInd}
                    />
                  </div>
                  <div>
                    <Swapbutton onClick={() => swapButton()} />
                  </div>
                </AutocompleteParent>
                <SubmitParent screentype="desktop">
                  <Menu options={options} setSelect={setSelect} select={select} />
                  {
                    select.value === 'depart' || select.value === 'arrive' ? <div><DateTimeContainer setDateTime={setDatetime} dateTime={datetime} /></div> : null
                  }
                </SubmitParent>
                {/* <Preferencebutton text="ADVANCE SETTINGS" /> */}
                <div style={{ textAlign: 'left', width: 182 }}>
                  <div style={{ padding: '0px 24px 0px 24px' }}>
                    <Searchbutton text="GO" onClick={() => btnSearch()} disabledBtn={disabledBtn} />
                  </div>
                  <div style={{
                    padding: '0px 24px 0px 24px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                  }}
                  >
                    <div style={{ fontSize: '8px', margin: '0px 2px 0px 0px' }}>Powered By</div>
                    <div>
                      <img
                        src={AMLogo}
                        alt="amlogo"
                        style={{
                          width: '56.0625px', height: '8.9px', position: 'relative', bottom: '2.5px',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Sidebar>
            )
          }
        <Map
          screentype="desktop"
          data={itenary}
          origin={{ lat: parseFloat(valFrom.lat), lng: parseFloat(valFrom.lng) }}
          destination={{ lat: parseFloat(valTo.lat), lng: parseFloat(valTo.lng) }}
          displayOp={displayOp}
          resultDetails={resultDetails}
          optionHover={optionHover}
          polyLineHover={polyLineHover}
          setCenterLat={setCenterLat}
          refForButton={refForButton}
          displayDet={displayDet}
        />
      </div>
    </div>
  );
}

export default Desktop;

Desktop.propTypes = {
  setAlignment: PropTypes.func.isRequired,
  alignment: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  setSwap: PropTypes.func.isRequired,
  swap: PropTypes.string.isRequired,
  setValFrom: PropTypes.func.isRequired,
  valFrom: PropTypes.string.isRequired,
  setValTo: PropTypes.func.isRequired,
  valTo: PropTypes.string.isRequired,
  setResultAPI: PropTypes.func.isRequired,
  resultAPI: PropTypes.string.isRequired,
  setResultAPITo: PropTypes.func.isRequired,
  resultAPITo: PropTypes.string.isRequired,
  setTxtvalue: PropTypes.func.isRequired,
  txtValue: PropTypes.string.isRequired,
  setTxtvalueTo: PropTypes.func.isRequired,
  txtValueTo: PropTypes.string.isRequired,
  setSelect: PropTypes.func.isRequired,
  select: PropTypes.shape().isRequired,
  setDatetime: PropTypes.func.isRequired,
  datetime: PropTypes.string.isRequired,
  setDisplayOp: PropTypes.func.isRequired,
  displayOp: PropTypes.bool.isRequired,
  setDisplayDet: PropTypes.func.isRequired,
  displayDet: PropTypes.bool.isRequired,
  options: PropTypes.shape().isRequired,
  journeyDetails: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  APIOptionCalled: PropTypes.func.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  setItenary: PropTypes.func.isRequired,
  itenary: PropTypes.arrayOf(shape()).isRequired,
  setDisplayRefresh: PropTypes.func.isRequired,
  displayRefresh: PropTypes.bool.isRequired,
  resultDetails: PropTypes.shape().isRequired,
  setResultDetails: PropTypes.func.isRequired,
  disabledBtn: PropTypes.bool.isRequired,
  setDetParking: PropTypes.func.isRequired,
  detParking: PropTypes.arrayOf(shape()).isRequired,
  setOptionHover: PropTypes.func.isRequired,
  optionHover: PropTypes.bool.isRequired,
  setPolyLineHover: PropTypes.func.isRequired,
  polyLineHover: PropTypes.shape().isRequired,
  btnResetTimer: PropTypes.func.isRequired,
  increment: PropTypes.func.isRequired,
  singleMode: PropTypes.arrayOf(shape()).isRequired,
  multiMode: PropTypes.arrayOf(shape()).isRequired,
  setSingleMode: PropTypes.func.isRequired,
  setMultiMode: PropTypes.func.isRequired,
  setUuid: PropTypes.func.isRequired,
  uuids: PropTypes.string.isRequired,
  setCenterLat: PropTypes.func.isRequired,
  centerLat: PropTypes.shape().isRequired,
  APISearchSelect: PropTypes.func.isRequired,
  onLoadCoord: PropTypes.shape().isRequired,
  loadCoordInd: PropTypes.bool.isRequired,
  refForButton: PropTypes.func.isRequired,
  manipulateElement: PropTypes.func.isRequired,
};
