/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import BusIcon from './image/bus.svg';
import WaitIcon from './image/wait.svg';
import AccordionIcon from './image/accordion.svg';
import GreenLocation from './image/green-location.svg';
import './busleg.css';

export default function BusLeg(props) {
  const { screentype, data, functionDuration } = props;
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('bus_accordion__icon_group_status');
  const [setActiveStop, setActiveStateStop] = useState('');
  const [setHeightStop, setHeightStateStop] = useState('0px');
  const [setRotateStop, setRotateStateStop] = useState('train_accordion__icon_group_status');

  // console.log('from bugLeg', data);

  const content = useRef(null);
  const contentTwo = useRef(null);

  const accordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`,
    );
    setRotateState(
      setActive === 'active' ? 'bus_accordion__icon_group_status' : 'bus_accordion__icon_group_status bus-rotate',
    );
  };
  const accordionTwo = () => {
    setActiveStateStop(setActiveStop === '' ? 'active' : '');
    setHeightStateStop(
      setActiveStop === 'active' ? '0px' : `${contentTwo.current.scrollHeight}px`,
    );
    setRotateStateStop(
      setActiveStop === 'active' ? 'train_accordion__icon_group_status' : 'train_accordion__icon_group_status train-rotate',
    );
  };
  const scheduleIn = (depature, arrival) => {
    const diffDepature = (depature - arrival) / 1000;
    return functionDuration(diffDepature);
  };
  return (
    <div className={screentype === 'desktop' ? 'journey-detail-bus-container' : 'journey-detail-mobile-bus-container'}>
      <div className="timeline-middle-bus">
        <img loading="lazy" src={WaitIcon} alt="wait" className="timeline-point-bus" />
      </div>
      <div className="timeline-component-bus">
        <div className="bus-platform">
          <div className="bus-platform-detail">
            <p style={{
              margin: '0px 4px 0px 0px', fontWeight: 'normal', fontSize: '12px', lineHeight: '17px',
            }}
            >
              Wait for
            </p>
            <div className="j-bus-route-detail">{data.routeShortName ? data.routeShortName : data.routeId}</div>
            <p style={{
              margin: '0px', fontWeight: 'bold', fontSize: '12px', lineHeight: '17px',
            }}
            >
              {data.route}
            </p>
          </div>
          <div style={{ height: '21px' }} />
          {/* <div role="button" tabIndex={0}
            onKeyPress={accordion} onClick={accordion} className="bus-schedule-perform">
            <div className="schedule-color-bus">Schedule</div>
            <img loading="lazy" src={AccordionIcon} alt="style" className={`${setRotate}`} />
          </div> */}
          {/* <div ref={content} style={{ maxHeight: `${setHeight}` }}
          className=" bus-schedule-list">
            <div>hello workd</div>
            <div>hello workd</div>
            <div>hello workd</div>
          </div> */}
        </div>
        <div className="bus-container">
          <div className="bus-container-first">Scheduled in</div>
          <div className="train-container-second">
            <div className="bus-container-second-number">{scheduleIn(data.from.departure, data.from.arrival)}</div>
            <div className="bus-container-second-measure">min</div>
          </div>
        </div>
      </div>
      <div className="timeline-middle-sd-bus">
        <img loading="lazy" src={BusIcon} alt="wait" className="timeline-point-sd-bus" />
      </div>
      <div className="timeline-component-bus">
        <div className="bus-platform">
          <div className="bus-platform-detail">
            <p style={{
              margin: '0px 4px 0px 0px', fontWeight: 'normal', fontSize: '12px', lineHeight: '17px',
            }}
            >
              Travel to
            </p>
            <div className="j-bus-route-detail">{data.routeShortName ? data.routeShortName : data.routeId}</div>
            <p style={{
              margin: '0px', fontSize: '12px', fontWeight: 'bold',
            }}
            >
              {data.to.name}
            </p>
          </div>
          <div className="journey-detail-bus-display">
            <div className="timeline-middle-stops-bus" style={{ borderColor: '#128110' }}>
              <img loading="lazy" src={GreenLocation} alt="wait" className="timeline-point-fr-bus" />
            </div>
            <div className="timeline-component-sd-bus">
              <div className="stopid-name-container-bus">
                {data.from.name}
              </div>
              {
                data.intermediateStops && data.intermediateStops.length > 1 && (
                  <div role="button" tabIndex={0} onKeyPress={accordionTwo} onClick={accordionTwo} className="bus-schedule-perform">
                    {data.intermediateStops && (
                      <div className="schedule-color-bus">
                        {data.intermediateStops.length}
                        {' '}
                        stops
                      </div>
                    )}
                    {/* <img loading="lazy" src={AccordionIcon} alt="style" className={`${setRotateStop}`} /> */}
                    <img loading="lazy" src={AccordionIcon} alt="style" className={setRotateStop} />
                  </div>
                )
              }
              <div
                ref={contentTwo}
                style={{ maxHeight: `${setHeightStop}` }}
                className="bus-stop-list"
              >
                {
                  data.intermediateStops && data.intermediateStops.length > 0
                  && data.intermediateStops.map((x) => (
                    <div>
                      <div
                        className="timeline-stop-middle-sd-bus-fr"
                        style={{ borderColor: '#E0115F' }}
                      >
                        {setActiveStop === 'active' && (
                          <img
                            loading="lazy"
                            src={GreenLocation}
                            alt="wait"
                            className="timeline-point-fr-sd-bus-ac"
                          />
                        )}
                      </div>
                      <div className="timeline-component-sd-bus-fr">
                        <div className={setActiveStop === 'active'
                          ? 'stopid-name-container-bus-fr text-bus-nothidden'
                          : 'stopid-name-container-bus-fr text-bus-hidden'}
                        >
                          {x.name}
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>
            </div>
            <div className="timeline-stop-middle-sd-bus" style={{ borderColor: '#E0115F' }}>
              <img loading="lazy" src={GreenLocation} alt="wait" className="timeline-point-fr-sd-bus" />
            </div>
            <div className="timeline-component-sd-bus">
              <div className="stopid-name-container-bus">
                {data.to.name}
              </div>
            </div>
          </div>
        </div>
        <div className="bus-container-stop-name">
          <div className="bus-container-second-one">
            <div className="bus-container-second-one-number">{functionDuration(data.duration)}</div>
            <div className="bus-container-second-one-measure">min</div>
          </div>

        </div>
      </div>
    </div>
  );
}

BusLeg.propTypes = {
  /**
      screentype desktop, tablet, mobile
    */
  screentype: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  functionDuration: PropTypes.func.isRequired,
};
