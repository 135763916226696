import React from 'react';
import PropTypes from 'prop-types';
import './journeyaddress.css';

export default function JourneyAddress(props) {
  const { children, screentype } = props;
  const journeyAddress = (type) => {
    switch (type) {
      case 'desktop':
        return (
          <div className="journeyaddress-parent-desktop-container">
            {children}
          </div>
        );
      case 'tablet':
        return (
          <div className="joureyaddress-parent-mobile-container">
            {children}
          </div>
        );
      case 'mobile':
        return (
          <div className="joureyaddress-parent-mobile-container">
            {children}
          </div>
        );
      default:
        return (
          <div className="journeyaddress-parent-desktop-container">
            {children}
          </div>
        );
    }
  };
  return (
    <div>
      {journeyAddress(screentype)}
    </div>
  );
}

JourneyAddress.propTypes = {
  /**
          children element
  */
  children: PropTypes.element,
  /**
      screentype desktop, tablet, mobile
  */
  screentype: PropTypes.string,
};

JourneyAddress.defaultProps = {
  children: <div />,
  screentype: 'desktop',
};
