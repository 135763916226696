/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useRef, useCallback, useLayoutEffect,
} from 'react';
import decodePolyline from 'decode-google-map-polyline';
import PropTypes from 'prop-types';
import {
  GoogleMap, Polyline, Marker, useLoadScript,
} from '@react-google-maps/api';
import OriginIcon from './image/origin.svg';
import DestinationIcon from './image/destination.svg';
import OnHoverMode from './OnHoverMode';
import DetailsModeDetails from './DetailsModeDetails';

const GOOGLE_TOKEN = 'AIzaSyDoIHcgafg5pz1_Ktu3eVlbeVtlvqcu0q0';
const libraries = ['places'];
const idMap = ['a353771ecdb00be3'];
const center = {
  lat: 3.1390,
  lng: 101.6869,
};

function Map(props) {
  const {
    screentype, data, origin, destination, displayOp, resultDetails, optionHover,
    polyLineHover, displayMap, setCenterLat, refForButton, displayDet,
  } = props;
  const mapRef = useRef();

  // this to remove / display google map button settigs
  const options = {
    streetViewControl: false,
    fullscreenControl: false,
    mapId: 'a353771ecdb00be3',
  };
  const [polylineReal, setPolylineReal] = useState([]);
  const [polylineWith, setPolylineWith] = useState([]);
  const [refMap, setRefMap] = useState(null);
  // add in the google token
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_TOKEN,
    libraries,
    mapIds: idMap,
  });

  // decode a polylines
  const polylineDecode = useCallback((x) => {
    const encodedPolyline = x;
    const decodedPolyline = decodePolyline(encodedPolyline);
    return decodedPolyline;
  }, []);

  // this to call the fitbound function.
  const fitbounds = (value) => {
    if (window.google) { // when start up it will return undefined value
      const bounds = new window.google.maps.LatLngBounds();
      // eslint-disable-next-line array-callback-return
      value.map((x) => x.map((y) => {
        const pointsMap = y && polylineDecode(y);
        if (pointsMap) {
          pointsMap.map((i) => bounds.extend(i));
          if (mapRef.current) {
            mapRef.current.fitBounds(bounds);
          }
        }
      }));
    }
  };

  const fitboundsMobile = (value) => {
    if (window.google) { // when start up it will return undefined value
      const bounds = new window.google.maps.LatLngBounds();
      const mobile = value.legs.map((x) => x.legGeometry && x.legGeometry.points);
      const pointsMap = mobile.map((x) => x && polylineDecode(x));
      // eslint-disable-next-line array-callback-return
      pointsMap.map((u) => {
        if (u) {
          // eslint-disable-next-line array-callback-return
          u.map((i) => {
            bounds.extend(i);
            if (mapRef.current) {
              mapRef.current.fitBounds(bounds);
            }
          });
        }
      });
    }
  };

  // fitbound origin
  const fitbounsOrigin = (value) => {
    if (window.google) {
      const bounds = new window.google.maps.LatLngBounds();
      // eslint-disable-next-line array-callback-return
      value.map((x) => bounds.extend(x));
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    }
  };

  // fitbound
  useEffect(() => {
    if (data.length > 0 && screentype === 'desktop') {
      const legs = data.map((x) => x.legs);
      const geoMetryPoint = legs.map((x) => x.map((y) => y.legGeometry && y.legGeometry.points));
      // console.log('desktop polyline', geoMetryPoint);
      setPolylineReal(geoMetryPoint);
      // setPolylineWith(legs);
    }
  }, [data]);

  // the fitbound have to add in useEffect if there is changes on state.
  useEffect(() => {
    if (polylineReal.length > 0 && screentype === 'desktop') {
      fitbounds(polylineReal);
    }
  }, [polylineReal]);

  // fitbounds for start and end point;
  useEffect(() => {
    if (screentype === 'desktop') {
      if (origin.lat && destination.lat) {
        const latlong = [origin, destination];
        fitbounsOrigin(latlong);
      } else if (origin.lat) {
        const latlong = [origin];
        fitbounsOrigin(latlong);
      } else if (destination.lat) {
        const latlong = [destination];
        fitbounsOrigin(latlong);
      }
    }
  }, [origin || destination]);

  // this is for responsive height for map
  const [size, setSize] = useState({
    y: window.innerHeight,
    x: window.innerWidth,
  });
  /// --------------this is for responsive height to fixed the empty space in big screen
  useLayoutEffect(() => {
    function updateSize() {
      setSize({
        y: window.innerHeight,
        x: window.innerWidth,
      }); // take the windows height and - 56px. The 56px are height from top bar
    }
    window.addEventListener('resize', updateSize);
    updateSize(); // update the size based on screen change
    return () => window.removeEventListener('resize', updateSize); // return the updated height value, this value will add in div elements for sideBar and Panel
  }, []);

  const onMapLoad = useCallback((map) => {
    // console.log(map);
    mapRef.current = map;
    setRefMap(map);
  }, []);

  const onMapLoadMobile = useCallback((map) => {
    mapRef.current = map;
    fitboundsMobile(resultDetails);
    setRefMap(map);
  }, []);

  const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeWeight: 4,
    strokeOpacity: optionHover.hover ? 0.5 : 1,
    scale: 3,
  };

  // take the center of the map to call the search API
  const handleCenterChanged = useCallback(() => {
    if (refMap) {
      const newCenter = refMap.getCenter();
      const latitude = newCenter.lat();
      const longitude = newCenter.lng();
      setCenterLat({
        lat: latitude,
        lng: longitude,
      });
    }
  }, []);

  if (loadError) return 'Error';
  if (!isLoaded) return 'Loading';
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <div style={{
      position: 'relative', height: '100%', width: '100%', top: screentype === 'desktop' ? '44px' : '0px',
    }}
    >
      <GoogleMap
        id="map"
        mapContainerStyle={{ minHeight: screentype === 'desktop' ? `${size.y - 44}px` : '100%', width: '100%' }}
        center={center}
        zoom={10}
        onLoad={screentype === 'desktop' ? onMapLoad : onMapLoadMobile}
        options={options}
        onCenterChanged={handleCenterChanged}
      >
        { /* Child components, such as markers, info windows, etc. */}
        <>
          {/* {
            screentype === 'desktop' && (
              <div
                ref={refForButton}
                style={{
                  display: !displayDet ? 'none' : 'flex',
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  zIndex: 5,
                  top: '9px',
                  padding: '12px',
                  borderRadius: '8px',
                  right: '16px',
                  backgroundColor: 'rgba(255, 255, 255, .9)',
                }}
              />
            )
          } */}

          {
            data.length > 0 && Object.keys(resultDetails).length === 0
            && data.map((x) => x.legs.map((y) => (
              y.legGeometry && y.legGeometry.points ? (
                <Polyline
                  path={polylineDecode(y.legGeometry.points)}
                  options={{
                    strokeColor: '#939598',
                    strokeOpacity: y.mode === 'WALK' ? 0 : optionHover.hover ? 0.5 : 1,
                    strokeWeight: 5,
                    icons: [
                      {
                        icon: y.mode === 'WALK' ? lineSymbol : null,
                        offset: '0',
                        repeat: '20px',
                      },
                    ],
                  }}
                />
              ) : (
                y.mode !== 'PARK' && (
                  <Polyline
                    path={[{
                      lat: y.from.lat,
                      lng: y.from.lon,
                    },
                    {
                      lat: y.to.lat,
                      lng: y.to.lon,
                    }]}
                    options={{
                      strokeColor: '#939598',
                      strokeOpacity: optionHover.hover ? 0.5 : 1,
                      strokeWeight: 5,
                      icons: [
                        {
                          offset: '0',
                          repeat: '20px',
                        },
                      ],
                    }}
                  />
                )
              )
            )))
          }
          <DetailsModeDetails
            data={resultDetails}
            polylineDecode={polylineDecode}
            lineSymbol={lineSymbol}
          />
          <OnHoverMode
            data={polyLineHover}
            optionHover={optionHover}
            polylineDecode={polylineDecode}
            lineSymbol={lineSymbol}
          />
          {
            !displayOp && origin.lat && destination.lat && (
              <Polyline
                path={[origin, destination]}
                options={{
                  strokeColor: '#30638E',
                  // strokeWeight: 5,
                  strokeOpacity: 0,
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '0',
                      repeat: '20px',
                    },
                  ],
                }}
              />
            )
          }
          <Marker
            key={0}
            position={origin}
            icon={{
              url: OriginIcon,
              offset: '2',
            }}
          />
          <Marker
            key={1}
            position={destination}
            icon={{
              url: DestinationIcon,
              offset: '2',
            }}
          />
        </>
      </GoogleMap>
    </div>
  );
}

export default React.memo(Map);

Map.propTypes = {
  /**
            to display time.
        */
  screentype: PropTypes.string.isRequired,
  /**
            to display all the data
  */
  data: PropTypes.shape([]).isRequired,
  /**
            lat long origin value in object
  */
  origin: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }).isRequired,
  /**
            lat long destination value in object
  */
  destination: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }).isRequired,
  displayOp: PropTypes.bool.isRequired,
  resultDetails: PropTypes.shape().isRequired,
  polyLineHover: PropTypes.shape([]).isRequired,
  optionHover: PropTypes.bool.isRequired,
  displayMap: PropTypes.bool.isRequired,
  setCenterLat: PropTypes.shape().isRequired,
  refForButton: PropTypes.func.isRequired,
  displayDet: PropTypes.bool.isRequired,
};
